/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";



const getAllManufacturerCMS = async () => {
  try {
    const response = await axiosInstance.get(`/api/AdminManufacturer/get_all`);
    if (response.data) {
      return {
        data: response.data,
      };
    } else {
      return { message: "Manufacturer is null." };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const createManufacturerCMS = async (obj: any) => {
  try {
    const response = await axiosInstance.post(`/api/AdminManufacturer/create`, obj);
    if (response) {
      return {
        response,
        message: "Create manufacturer successfully!",
      };
    } else {
      return { message: "Something wrong! Try again" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const updateManufacturerCMS = async (obj: any) => {
  try {
    const response = await axiosInstance.post(`/api/AdminManufacturer/update`, obj);
    if (response) {
      return {
        response,
        message: "Update manufacturer successfully!",
      };
    } else {
      return { message: "Something wrong! Try again" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};

const deleteManufacturerCMS = async (id: any) => {
  try {
    const response = await axiosInstance.post(`/api/AdminManufacturer/delete`, id);
    if (response) {
      return {
        response,
        message: "Delete manufacturer successfully!",
      };
    } else {
      return { message: "Something wrong! Try again" };
    }
  } catch (error: any) {
    throw {
      error: error.response ? error.response.data : error.message,
      message: "Internal server error.",
    };
  }
};



const ManufacturerService = {
  getAllManufacturerCMS,
  createManufacturerCMS,
  updateManufacturerCMS,
  deleteManufacturerCMS
};

export default ManufacturerService;
