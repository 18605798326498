import React, { useState } from "react";
import { Divider, Radio, Table } from "antd";
import type { TableColumnsType } from "antd";
import SendIcon from "../../assets/icons/SendIcon";
import EditIcon from "../../assets/icons/EditIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
// import DeleteTableIcon from "@/libraries/assets/icons/DeleteTableIcon";
// import SendInvoiceIcon from "@/libraries/assets/icons/SendInvoiceIcons";
// import EditIcon from "@/libraries/assets/icons/EditIcon";
// import CustomerInvoice from "./customer-invoice";

interface TableListPCProps {
  bgStatus: string;
  textColorStatus: string;
}
interface DataType {
  key: React.Key;
  number: string;
  createdDate: string;
  dueDate: string;
  status: any;
  role: string;
  //   amount: string;
  action: any;
}

const TableListPC = (props: TableListPCProps) => {
  const { bgStatus, textColorStatus } = props;

  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <div className="flex justify-center items-center">
          <div className="text-center min-h-5 w-20 ">Number</div>
        </div>
      ),
      dataIndex: "number",
      render: (text: string) => (
        <td className="flex justify-center items-center">
          <div className="text-center w-20">{text}</div>
        </td>
      ),
    },
    {
      title: (
        <div className="flex justify-center items-center">
          <div className="pl-8 min-h-5 w-40 text-center">CUSTOMER NAME</div>
        </div>
      ),
      dataIndex: "customer",
      // render: (text: string) => <div className="text-center">{text}</div>,
    },
    {
      title: (
        <div className="flex justify-center items-center">
          <div className="text-center min-h-5 w-28">CREATED DATE</div>
        </div>
      ),
      dataIndex: "createdDate",
      render: (text: string) => (
        <td className="flex justify-center items-center">
          <div className="min-h-5 flex justify-center items-center w-20">
            {text}
          </div>
        </td>
      ),
    },
    {
      title: (
        <div className="flex justify-center items-center">
          <div className="min-h-5 text-center ">DUE DATE</div>
        </div>
      ),
      dataIndex: "dueDate",
      render: (text: string) => (
        <td className="flex justify-center items-center">
          <div className=" min-h-5 flex justify-center items-center w-20">
            {text}
          </div>
        </td>
      ),
    },
    {
      title: (
        <div className="flex justify-center items-center">
          <div className="text-center min-h-5 w-20">STATUS</div>
        </div>
      ),
      dataIndex: "status",
      render: (text: string) => (
        <div className="flex justify-center items-center">
          <div
            className={`bg bg-success bg-opacity-20 text-success text-center rounded-xl w-2/3 p-1`}
          >
            {text}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="flex justify-center items-center">
          <div className="text-center min-h-5 w-28">Role</div>
        </div>
      ),
      dataIndex: "role",
      render: (text: string) => (
        <td className="flex justify-center items-center">
          <div className=" min-h-5 flex  justify-center items-center w-20">
            {text}
          </div>
        </td>
      ),
    },
    {
      title: (
        <div className="flex justify-center items-center">
          <div className="text-center min-h-5 w-20">ACTION</div>
        </div>
      ),
      dataIndex: "action",
      render: (_: any, record: DataType) => (
        <div className="flex space-x-2 justify-center items-center min-h-5">
          <div className="group rounded-full bg-bgTotalSales bg-opacity-10 hover:bg-bgTotalSales hover:bg-opacity-50 cursor-pointer p-2">
            <EditIcon
            //   iconStyles={
            //     "w-4 h-4 group-hover:stroke-white group-hover:fill-white stroke-bgTotalSales cursor-pointer"
            //   }
            />
          </div>
          <div className="group rounded-full bg-danger bg-opacity-20 hover:bg-danger cursor-pointer p-2">
            <DeleteIcon
            //   iconStyles={
            //     "w-4 h-4  group-hover:fill-white fill-danger cursor-pointer"
            //   }
            />
          </div>
        </div>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      number: "1",
      createdDate: "25-09-2022",
      dueDate: "25-09-2022",
      status: "Paid",
      role: "Admin",
      action: null,
    },
    {
      key: "2",
      number: "2",
      createdDate: "25-09-2022",
      dueDate: "25-09-2022",
      status: "Paid",
      role: "Admin",

      action: null,
    },
    {
      key: "3",
      number: "3",
      createdDate: "25-09-2022",
      dueDate: "25-09-2022",
      status: "Paid",
      role: "Admin",
      action: null,
    },
    {
      key: "4",
      number: "4",
      createdDate: "25-09-2022",
      dueDate: "25-09-2022",
      status: "Paid",
      role: "Admin",
      action: null,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.number === "Disabled User", // Column configuration not to be checked
      number: record.number,
    }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  return (
    <div>
      {/* <Radio.Group
    onChange={({ target: { value } }) => {
      setSelectionType(value);
    }}
    value={selectionType}
  >
    <Radio value="checkbox">Checkbox</Radio>
    <Radio value="radio">radio</Radio>
  </Radio.Group> */}

      <Divider />

      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        bordered
        scroll={{ x: "100%" }}
      />
    </div>
  );
};

export default TableListPC;
