import React, { useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'antd';
import NewsService from '../../services/news';

const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await NewsService.uploadFileNewsCMS(formData);
        // console.log(response, 'img res');

        const imageUrl = `https://api.lehieucomputer.com/${response.response?.data.data}`;
        return imageUrl;
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
};

const QuillEditor: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
    const editorRef = useRef<ReactQuill>(null);

    const handleImageUpload = useCallback(async (file: File) => {
        try {
            const imageUrl = await uploadImage(file);
            if (editorRef.current) {
                const quill = (editorRef.current.getEditor() as any);
                const range = quill.getSelection();
                quill.insertEmbed(range.index, 'image', imageUrl);
            }
        } catch (error) {
            console.error('Error inserting image:', error);
        }
    }, []);

    const handleToolbarImageClick = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files?.[0];
            if (file) {
                await handleImageUpload(file);
            }
        };
    }, [handleImageUpload]);

    const modules = {
        toolbar: {
            container: [
                // [{ 'header': '1' }, { 'header': '2' }, { 'header': '3' }, { 'header': '4' }],
                [{ 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ color: [] }, { background: [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'video'],
                [{ 'align': [] }],
            ],
            handlers: {
                'image': handleToolbarImageClick,
            },
        },

    };

    const formats = [
        'header', 'font', 'size', 'color', 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'link', 'image', 'video', 'align', 'br'
    ];

    return (
        <ReactQuill
            ref={editorRef}
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
        />
    );
};

const QuillEditorWrapper: React.FC<any> = (props) => {
    const { value, onChange, ...rest } = props;

    return (
        <Form.Item {...rest}>
            <QuillEditor value={value} onChange={onChange} />
        </Form.Item>
    );
};

export default QuillEditorWrapper;
