/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Breadcrumb, Card, Carousel, Drawer } from "antd";
import PlusIconComponent from "../../assets/icons/PlusIcon";
import ItemListCardComponent from "../../components/item-component/item-list-card-component";
import SliderComponent from "../../components/slider/slider-component";
import HomeService from "../../services/home";
import TabSidebarIconComponent from "../../assets/icons/TabSidebarIcon";
import CommentIconComponent from "../../assets/icons/CommentIcon";
import ShieldIconComponent from "../../assets/icons/ShieldIcon";
import ItemListCollectionComponent from "../../components/item-component/item-list-collection-component";
import ImageModal from "../../components/item-component/image-modal-component";
import ProductCollectionService from "../../services/product-collection";

function Homepage() {
  const [listCateProd, setListCateProd] = useState([]);
  const [listCate, setListCate] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [listProductSale, setListProductSale] = useState([]);

  const [productCollection, setProductCollection] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openSubCate, setOpenSubCate] = useState(false);
  const [subCategory, setSubCategory] = useState<any>(null);
  const [images, setImages] = useState([]);
  const [textSale, setTextSale] = useState('');
  const [imageFirst, setImageFirst] = useState(null);

  const showLoading = () => {
    setOpen(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const showSubCateMenu = (subCate: any) => {
    setSubCategory(subCate);
    setOpenSubCate(true);
  };

  const handleCloseSubCateMenu = () => {
    setOpenSubCate(false);
  };

  const fetchCategories = async () => {
    try {
      const result = await HomeService.getAllCategory();

      if (result.data.message === 'success') {
        const data = result.data.data;
        setListCate(data);
      } else {
        console.error("Unexpected response message:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };


  const fetchListSales = async () => {
    try {
      const result = await HomeService.getAllSalesByHome();

      if (result.data.message === 'success') {
        const data = result.data.data;
        const reverseListSale = data.reverse();
        setListProductSale(reverseListSale);
      } else {
        console.error("Unexpected response message:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching list sales:", error);
    }
  };


  const fetchCategoriesAndProd = async () => {
    try {
      const { data } = await HomeService.getAllProductByHome();

      if (data.message === 'success') {
        setListCateProd(data.data || []);
        setListProduct(data.data?.products || []);
      } else {
        console.error("Unexpected response message:", data.message);
      }
    } catch (error) {
      console.error("Error fetching categories and products:", error);
    }
  };


  const fetchTextSale = async () => {
    try {
      const { data } = await HomeService.getTextSalesByHome();

      if (data.message === 'success') {
        setTextSale(data.data?.text || '');
      } else {
        console.error("Unexpected response message:", data.message);
      }
    } catch (error) {
      console.error("Error fetching text sale:", error);
    }
  };


  const fetchImages = async () => {
    try {
      const result = await HomeService.getAllBannerByHome();

      if (result.data.message === 'success') {
        const data = result.data.data;
        const bannerFirst = data.find((item: any) => item.homeType === "BANNER_FIRST");
        setImageFirst(bannerFirst)
        const imageArray = data
          .filter((item: any) => item.homeType === "BANNER_FIX")
          .map((item: any) => item.image);
        setImages(imageArray);
      } else {
        console.error("Unexpected response message:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };


  const fetchProductCollection = async () => {
    try {
      const result = await ProductCollectionService.getAllProductCollectionHome();
      if (result.data.message === "success") {
        setProductCollection(result.data.data);
      } else {
        console.error("Unexpected response message:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching product collection:", error);
    }
  };




  useEffect(() => {
    fetchCategories();
    fetchCategoriesAndProd();
    fetchImages();
    fetchListSales();
    fetchTextSale();
    fetchProductCollection();
  }, []);


  return (

    <div className="container md:py-5 py-2 mx-auto">
      <ImageModal arrayImage={imageFirst} />

      <div className="item-under-banner container mx-auto xxxxl:w-full xxs:w-[1400px] xs:px-0 px-4 ">
        <SliderComponent />
      </div>
      <div className="container mx-auto xxxxl:w-full xxs:w-[1400px]">
        <div className="mt-3 md:flex hidden flex-wrap xs:-mx-4">
          {images.slice(0, 3).map((image, index) => (
            <a
              href=""
              target="_blank"
              className="md:w-1/3 w-full px-4 my-2 relative overflow-hidden group"
              key={index}
            >
              <div className="absolute top-0 -left-full bottom-0 w-full transform -skew-x-24 bg-gradient-to-r from-white/40 to-transparent transition-transform duration-700 ease-in-out group-hover:translate-x-[210%]"></div>
              <img
                src={`https://api.lehieucomputer.com${image}`}
                alt={`Banner ${index + 1}`}
                className="block max-w-full w-full rounded-lg object-cover"
              />
            </a>
          ))}
        </div>
      </div>

      <div className="flex justify-between items-center mt-5 px-4 md:hidden">
        <div className="flex flex-col items-center w-1/3"

        >
          <div className="w-10 h-10 bg-colorSecondary rounded-full flex justify-center items-center"
            onClick={showLoading}>
            <TabSidebarIconComponent style="w-5 h-5 fill-white" />
          </div>
          <div className="text-sm text-center px-3 py-0.5">Danh mục sản phẩm</div>

        </div>
        <Drawer
          closable={false}
          destroyOnClose
          title={<div className='text-black/30 text-sm text-center'>Menu</div>}
          placement="left"
          open={open}
          loading={loading}
          onClose={() => setOpen(false)}
          width={250}
        >
          {listCate.map((cate: any) => {
            return (
              <div
                key={cate.id}
                className="cursor-pointer flex justify-between items-center py-3 relative "
              >
                <a href={`/danh-muc/${cate.categoryNameSlug}`} className='text-center uppercase font-semibold'>
                  {cate.categoryName}
                </a>
                {(cate.subcategories).length > 0 &&
                  <div className='w-6 flex justify-center items-center' onClick={() => showSubCateMenu(cate)}>
                    &#10095;
                  </div>
                }

              </div>
            )
          })}
          <div
            className="cursor-pointer flex justify-between items-center py-3 relative "
          >
            <a href={`/dang-ky`} className=' text-center uppercase font-semibold'>
              Đăng ký thành viên
            </a>
          </div>
        </Drawer>
        <Drawer
          closable
          destroyOnClose
          title={<div className='text-black/30 text-sm text-center'>{subCategory?.categoryName}</div>}
          placement="left"
          open={openSubCate}
          onClose={handleCloseSubCateMenu}
          width={250}
          closeIcon={<div>&#10094;</div>}
        >
          {subCategory?.subcategories.map((subCate: any) => {

            return (
              <div
                key={subCate.id}
                className="cursor-pointer flex justify-between items-center py-3 relative"
              >
                <a href={`/danh-muc/${subCate.categoryNameSlug}`} className='text-center uppercase font-semibold'>
                  {subCate.categoryName}
                </a>
                {(subCate.subcategories).length > 0 &&
                  <div className='w-6 flex justify-center items-center'>
                    &#10095;
                  </div>
                }
              </div>
            )
          })}
        </Drawer>
        <a className="flex flex-col items-center w-1/3"
          href="/tin-tuc/tin-cong-nghe"
        >
          <div className="w-10 h-10 bg-colorSecondary rounded-full flex justify-center items-center">
            <CommentIconComponent style="w-5 h-5 fill-white" />
          </div>
          <div className="text-sm text-center px-3 py-0.5">Trang tin công nghệ</div>
        </a>
        <a className="flex flex-col items-center w-1/3"
          href="/tin-tuc/bao-hanh"
        >
          <div className="w-10 h-10 bg-colorSecondary rounded-full flex justify-center items-center">
            <ShieldIconComponent style="w-5 h-5 fill-white" />
          </div>
          <div className="text-sm text-center px-3 py-0.5">Tra cứu bảo hành</div>
        </a>
      </div>
      <div className="container mx-auto xxxxl:w-full xxs:w-300 mt-4">
        <div className=" md:px-4 xs:-mx-4" >
          <div className='bg-navMenu font-semibold px-4 md:mx-0 mx-4 py-3 rounded-lg'>Bộ sưu tập</div>
          <div className=" rounded-lg my-5 md:shadow-xl md:bg-white flex flex-col" >

            <div className=" md:px-6 px-1.5 rounded-lg md:bg-white text-sm">
              <ItemListCollectionComponent productCollection={productCollection} />
            </div>
          </div>
        </div>
      </div>
      {listProductSale.length > 0 &&
        <div className="container mx-auto xxxxl:w-full xxs:w-300">
          <div className=" md:px-4 md:-mx-4 xs:mx-0 mx-4" >
            <div className="bg-sale-bg rounded-lg ">
              <div className=' font-semibold px-4 py-2 text-white md:text-xl xs:text-base text-sm uppercase tracking-widest animate-pulse'>{textSale}</div>
              <div className="  mb-5 md:shadow-xl flex flex-col" >

                <div className=" md:px-6 px-1.5 py-2 text-sm">
                  <ItemListCardComponent products={listProductSale} />
                </div>
              </div>
            </div>

          </div>
        </div>
      }


      <div className="container mx-auto xxxxl:w-full xxs:w-300">
        {
          listCateProd.length > 0 && listCateProd.map((cate: any, index: number) => {
            const reverseListProduct = (cate.products).reverse()
            return (
              <>
                <div className="md:px-4 xs:-mx-4" key={index}>
                  {reverseListProduct.length > 0 &&
                    <div className=" rounded-lg my-5 md:shadow-xl md:bg-white" >

                      <div className="bg-gray sm:pr-5 pr-2 rounded-t-lg md:flex hidden justify-between relative" >

                        {/* <div className=" uppercase xl:text-2xl lg2:text-xl text-sm font-bold bg-tag-name-category bg-tag-size sm:pr-12 pr-5 sm:pl-5 pl-2 float-left text-white !leading-15 rounded-tl-lg">
                        {cate.categoryName}
                      </div> */}
                        <div
                          className="relative w-72 h-16 text-white font-bold flex items-center justify-start pl-5"
                          style={{
                            background: 'linear-gradient(to right, #E30B18, #F7E20E)',
                            borderTopLeftRadius: '8px',
                            clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0% 100%)',
                          }}
                        >
                          <span className="uppercase xl:text-2xl lg2:text-xl text-sm">
                            {cate.categoryName}
                          </span>

                        </div>
                        <div
                          className="absolute left-[275px] h-14 w-1.5"
                          style={{
                            transform: 'skewX(-24deg)',
                            background: 'linear-gradient(to right,#F7E20E 20%,#E30B18 )',
                          }}
                        ></div>



                        <div className=" h-15 flex items-center justify-end">


                          <a href={`/danh-muc/${cate.categoryNameSlug}`} className="flex justify-center items-center cursor-pointer group " >
                            <div className="text-colorSecondary font-bold xs:mr-1 mr-0.5 duration-300 group-hover:text-colorThird uppercase lg2:text-base xs:text-xs text-2xs">Xem tất cả</div>
                            <PlusIconComponent style="lg2:w-5 lg2:h-5 w-3.5 h-3.5 stroke-colorSecondary duration-300 group-hover:stroke-colorThird mb-1" />
                          </a>
                        </div>

                      </div>
                      <div className="mx-4 mt-2 rounded-lg bg-gradient-to-r from-[#E30B18] to-[#F7E20E] px-2.5 py-3 md:hidden flex justify-between items-center">
                        <div className="text-base font-semibold text-white">{cate.categoryName}</div>

                        <a href={`/danh-muc/${cate.categoryNameSlug}`} className="flex justify-center items-center cursor-pointer group ">
                          <div className="text-white font-bold xs:mr-1 mr-0.5 duration-300 group-hover:text-colorThird uppercase lg2:text-base xs:text-xs text-2xs">Xem tất cả</div>
                          <PlusIconComponent style="lg2:w-5 lg2:h-5 w-3.5 h-3.5 stroke-white duration-300 group-hover:stroke-colorThird mb-1" />
                        </a>
                      </div>



                      <div className=" md:px-6 px-1.5 py-2 rounded-lg md:bg-white text-sm">
                        <ItemListCardComponent products={reverseListProduct} />
                      </div>

                    </div>
                  }



                </div>
              </>

            )
          })
        }
      </div>

    </div >
  );
}

export default Homepage;
