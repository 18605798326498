import { Button, Select } from "antd";
import TableListAdmin from "./table-list-admin";
import Search from "antd/es/input/Search";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import ArrowRight from "../../assets/icons/ArrowRight";
import TableListPc from "./table-list-pc";

function PcPage() {
  const handleDateChange = () => {};

  const handleChange = () => {};
  return (
    <div className="bg-bgDashboard px-5">
      <div className="px-2 py-5 flex flex-col lg2:flex-row lg2:justify-between"></div>

      <div className="flex justify-between items-center">
        <div className="flex flex-col lg:flex-row justify-between p-6">
          <div className="flex">
            <div className="w-full mr-4 md:w-full mb-3 md:mb-0 lg:w-full sm:w-1/2 h-10 ">
              <Search
                placeholder="Search for items"
                className="h-full flex items-center"
                size="large"
              />
            </div>
            <div className="flex mb-2 md:items-center md:mb-0">
              <div className="bg bg-danger w-10 h-10 flex justify-center items-center px-2 py-3 rounded">
                <DeleteIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="bg bg-white">
          <div className="">
            <Button className="text-white p-2 bg bg-colorPrimary flex justify-center items-center py-2 h-10">
              Create New Product
            </Button>
          </div>
        </div>
      </div>

      <div className="xl:overflow-auto overflow-visible lg:w-full">
        <TableListPc bgStatus="" textColorStatus="" />
      </div>
    </div>
  );
}

export default PcPage;
