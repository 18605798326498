/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Pagination, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import ClockIconComponent from '../../assets/icons/ClockIcon'
import ArrowDownIconComponent from '../../assets/icons/ArrowDownIcon'
import { useLocation, useParams } from 'react-router-dom';
import NewsService from '../../services/news';

function ListNewsPage() {
    const { slug } = useParams();
    const location = useLocation();
    const [placeholder, setPlaceholder] = useState('');
    const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
    const [cateNews, setCateNews] = useState([]);
    const [listNewsBySlug, setListNewsBySlug] = useState([]);

    const handleChange = (value: string) => {
        setSelectedValue(value);
        window.location.href = `/tin-tuc/${value}`;
    };

    const fetchCategoryNews = async () => {
        const { data } = await NewsService.getNewsCategory();

        if (data.message === 'success') {
            setCateNews(data.data);
            // console.log(data.data, 'data cate news')
        }
    };

    const fetchNewsFollowSlug = async (slug: any) => {
        const { data } = await NewsService.getNewsBySlug(slug);

        if (data.message === 'success') {
            setListNewsBySlug(data.data);
        }
    };

    const foundCategory: any = cateNews.find((item: any) => item.slug === slug);

    const categoryName: any = foundCategory ? foundCategory.categoryName : "Không tìm thấy";

    useEffect(() => {

        fetchNewsFollowSlug(slug);
    }, [slug]);


    useEffect(() => {
        fetchCategoryNews();
    }, []);

    return (
        <div>
            <div className='container mx-auto sm:px-0 px-4 xxs:w-300 xxxxl:w-full'>
                <div className='py-3.5'>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: <div><a href="/" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a></div>,
                            },
                            {
                                title: <div><a href="/tin-tuc" className="hover:!text-colorPrimary hover:!bg-white">Tin tức</a></div>,
                            },
                            {
                                title: <div><a href={`/tin-tuc/${slug}`} className="hover:!text-colorPrimary hover:!bg-white">{categoryName}</a></div>,
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='container mx-auto sm:px-0 px-4 xxs:w-300 xxxxl:w-full'>
                <div className='md:block hidden'>
                    {cateNews.map((cate: any) => {
                        return (
                            <a href={`/tin-tuc/${cate.slug}`}
                                className={`text-lg uppercase font-semibold inline-block mr-8 mb-5 cursor-pointer ${slug === cate.slug ? 'text-colorSecondary' : ''}`}
                            >{cate.categoryName}</a>
                        )
                    })}
                </div>
                <div className='py-3.5 w-full md:hidden block'>
                    <Select
                        className='w-full'
                        showSearch
                        onChange={handleChange}
                        placeholder={placeholder}
                        value={selectedValue}
                        optionFilterProp="label"
                        suffixIcon={<ArrowDownIconComponent style='w-4 h-4 fill-black/50' />}
                        filterSort={(optionA, optionB) =>
                            String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                        }
                        options={cateNews.map((cate: any) => ({
                            value: cate.slug,
                            label: cate.categoryName,
                        }))}
                    />
                </div>
                <div className='md:p-8 p-4 bg-white md:rounded-lg shadow-lg'>
                    <div className='md:-mx-2 flex flex-wrap'>
                        {listNewsBySlug !== null && listNewsBySlug.length > 0 ? (
                            listNewsBySlug.map((newsItem: any, index) => {
                                const date = new Date(newsItem.publishedDate);

                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                const hours = date.getHours() % 12 || 12;
                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                const ampm = date.getHours() >= 12 ? 'pm' : 'am';
                                const formattedDate = `${month}-${day}-${year}, ${hours}:${minutes} ${ampm}`;
                                return (

                                    <a key={index} href={`/tin-tuc-chi-tiet/${newsItem.slug}`} className='xs:w-1/4 w-full md:px-2 mb-4 pb-2 md:border-none border-b border-b-black/20 group'>
                                        <div className='block relative mb-3.5 overflow-hidden'>
                                            <img src={`https://api.lehieucomputer.com${newsItem.logo}`} alt=""
                                                className='block m-auto transition-transform duration-300 ease-in-out transform group-hover:scale-110 max-w-full w-full object-cover h-72' />
                                        </div>
                                        <div>
                                            <div className='mb-2.5'>
                                                <div className='block md:text-base text-sm font-semibold hover:text-colorThird duration-300'>
                                                    {newsItem.title}
                                                </div>
                                            </div>
                                            <div className='flex items-center mr-6'>
                                                <ClockIconComponent style='w-4 h-4 stroke-black/50 mr-1' />
                                                <div className='text-black/50 text-sm'>{formattedDate}</div>
                                            </div>
                                        </div>
                                    </a>

                                );
                            })
                        ) : (
                            <div className="">Chưa có tin tức ở mục này</div>
                        )}
                    </div>
                    {listNewsBySlug !== null && listNewsBySlug.length > 0 && <Pagination align="center" defaultCurrent={1} total={listNewsBySlug.length} className='pt-8 pb-4' />}

                </div>

            </div>
        </div>
    )
}

export default ListNewsPage
