/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const AdminGetAll = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminOrders/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const AdminGetById = async (id: any) => {
    try {
        const response = await axiosInstance.get(`/api/AdminOrders/get_by_id?Id=${id}`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const changeStatusOrder = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminOrders/change_status?Id=${obj.Id}&Status=${obj.Status}`);
        if (response) {
            return {
                response,
                message: "Confirm order successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteOrderCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminOrders/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete Order successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};


const OrderService = {
    AdminGetAll,
    AdminGetById,
    changeStatusOrder,
    deleteOrderCMS
};

export default OrderService;
