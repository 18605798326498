/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";



const getAllProductAttributeCMS = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminProductAttribute/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Product Attribute is null." };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const createProductAttributeCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminProductAttribute/create`, obj);
        if (response) {
            return {
                response,
                message: "Create Product Attribute successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateProductAttributeCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminProductAttribute/update`, obj);
        if (response) {
            return {
                response,
                message: "Update Product Attribute successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteProductAttributeCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminProductAttribute/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete Product Attribute successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};



const ProductAttributeService = {
    getAllProductAttributeCMS,
    createProductAttributeCMS,
    updateProductAttributeCMS,
    deleteProductAttributeCMS
};

export default ProductAttributeService;
