import React from "react";
interface IConProps {
  style?: string;
  stroke?: string;
}

const ClockIconComponent: React.FC<IConProps> = ({ style }) => {
  return (
    <svg className={style} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00007 14.2858C11.4716 14.2858 14.2858 11.4716 14.2858 8.00007C14.2858 4.52857 11.4716 1.71436 8.00007 1.71436C4.52857 1.71436 1.71436 4.52857 1.71436 8.00007C1.71436 11.4716 4.52857 14.2858 8.00007 14.2858Z" className={style} strokeWidth="1.37143" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M8 4.57153L8 8.0001H11.4286" className={style} strokeWidth="1.37143" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>

  );
};

export default ClockIconComponent;
