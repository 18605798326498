/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import RequireAuth from "./requireAuth";
import UnauthorizedPage from "../pages/system/Unauthorized";
import Page403 from "../pages/system/403";
import Page404 from "../pages/system/404";

import Homepage from "../pages/system/Homepage";
import Login from "../pages/system/Login";
import { IRoute } from "../interfaces";
import ClientLayout from "../layouts/client-layout";
import AdminLayout from "../layouts/admin-layout";
import AdminPage from "../pages/admin/admin-page";
import Register from "../pages/system/Register";
import TechNews from "../pages/system/TechNews";
import Order from "../pages/system/Order";
import ListProductForm from "../pages/system/ListProduct";
import PcPage from "../pages/admin/pc-page";
import ManageUser from "../pages/admin/manage/user/manage-user-frm";
import ManageCategory from "../pages/admin/manage/category/manage-category-frm";
import ManageProduct from "../pages/admin/manage/product/manage-product-frm";
import ManageNews from "../pages/admin/manage/news/manage-news-frm";
import SearchProductForm from "../pages/system/SearchProduct";
import Contact from "../pages/system/Contact";
import Introduction from "../pages/system/Introduction";
import NewsPage from "../pages/system/News";
import NewsTechnologyPage from "../pages/system/ListNews";
import NewsTechnologyDetailPage from "../pages/system/NewsTechnologyDetail";
import ListNewsPage from "../pages/system/ListNews";
import ManageNewsCateForm from "../pages/admin/manage/news-category/manage-news-category-frm";
import ManageOrders from "../pages/admin/manage/orders";
import ManageOrderDetail from "../pages/admin/manage/orders/detail";
import LoginAdmin from "../pages/admin/LoginAdmin";
import ChangePasswordComponent from "../pages/admin/manage/setting/ChangePassword";
import ManageManufacture from "../pages/admin/manage/manufacture/manage-manufacture-frm";
import ManageProductAttibute from "../pages/admin/manage/product-attribute/product-attribute-frm";
import ManageFAQ from "../pages/admin/manage/faq/manage-faq";
import ManageBanner from "../pages/admin/manage/banner/manage-banner-frm";
import ListCollectionForm from "../pages/system/ListCollection";
import ManageCollection from "../pages/admin/manage/collection/manage-collection-frm";
import RegisterMember from "../pages/system/RegisterMember";
import ManageCustomerForm from "../pages/admin/manage/customer/manage-customer-frm";
import ManageBank from "../pages/admin/manage/bank/manage-bank-frm";
import ManageText from "../pages/admin/manage/text-slider/manage-text";
import ManageTextPromotion from "../pages/admin/manage/text-promotion/manage-text-promotion";
import ManageSocialLink from "../pages/admin/manage/social-link/manage-social-link";
import ManageIntroduction from "../pages/admin/manage/introduce/manage-introduction-frm";
// import FailPage from '../system/403';

// import EditMessage from "../components/message/EditMessage";
// import EditTemplateHandmade from "../components/message-handmade/EditTemplateHandmade";
// import EditMessageHandmade from "../components/message-handmade/EditTemplateHandmade";

const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

const ProtectedRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" replace />;
};


function RenderRouters() {
  const renderRoute = (
    { children = [], element, permissions, ...rest }: IRoute,
    index: number,
    isAuth = false
  ) => {
    const renderElement = isAuth ? (
      <RequireAuth permissions={permissions}>{element}</RequireAuth>
    ) : (
      element
    );

    if (children && children.length > 0) {
      return (
        <Route {...rest} element={renderElement} key={index}>
          {children.map((route, indexx: number) => {
            return renderRoute(route, indexx, isAuth);
          })}
        </Route>
      );
    }

    return <Route {...rest} element={renderElement} key={index} />;
  };

  return (
    <Routes>
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute
            element={
              <AdminLayout>
                <Routes>
                  <Route path="/" element={<Navigate to="/admin/user" />} />{" "}
                  {/* Redirect mặc định */}
                  <Route path="/user" element={<ManageUser />} />
                  <Route path="/customer" element={<ManageCustomerForm />} />
                  <Route path="/login" element={<LoginAdmin />} />
                  <Route path="/category" element={<ManageCategory />} />
                  <Route path="/product" element={<ManageProduct />} />
                  <Route path="/bank" element={<ManageBank />} />
                  <Route path="/banner" element={<ManageBanner />} />
                  <Route path="/collection" element={<ManageCollection />} />
                  <Route path="/news" element={<ManageNews />} />
                  <Route path="/text" element={<ManageText />} />
                  <Route path="/text-promotion" element={<ManageTextPromotion />} />
                  <Route path="/social-link" element={<ManageSocialLink />} />
                  <Route path="/news-category" element={<ManageNewsCateForm />} />
                  <Route path="/don-hang" element={<ManageOrders />} />
                  <Route path="/faq" element={<ManageFAQ />} />
                  <Route path="/introduction" element={<ManageIntroduction />} />
                  <Route path="/manufacturer" element={<ManageManufacture />} />
                  <Route path="/product-attributes" element={<ManageProductAttibute />} />
                  <Route path="/don-hang/chi-tiet/:id" element={<ManageOrderDetail />} />
                  <Route path="/change-password" element={<ChangePasswordComponent />} />
                  {/* <Route path="/403" element={<FailPage />} /> */}
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </AdminLayout>
            }
          />
        }
      />
      <Route path="/login" element={<LoginAdmin />} />
      <Route
        path="/*"
        element={
          <ClientLayout>
            <Routes>
              <Route path="/san-pham/:slug.html" element={<TechNews />} />

              <Route path="/" element={<Homepage />} />


              <Route path="/danh-muc/:slug" element={<ListProductForm />} />
              <Route path="/tim-kiem" element={<SearchProductForm />} />
              <Route path="/thong-tin-lien-he" element={<Contact />} />
              <Route path="/gioi-thieu" element={<Introduction />} />
              <Route path="/tin-tuc" element={<NewsPage />} />
              <Route path="/tin-tuc/:slug" element={<ListNewsPage />} />
              <Route path="/bo-suu-tap/:slug" element={<ListCollectionForm />} />
              <Route path="/tin-tuc-chi-tiet/:slugNews" element={<NewsTechnologyDetailPage />} />
              <Route path="/*" element={<Page404 />} />
              <Route path="/order" element={<Order />} />
            </Routes>
          </ClientLayout>
        }
      />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/not-found" element={<Page404 />} />
      <Route path="/dang-ky" element={<RegisterMember />} />
      {/* <Route path="/dang-nhap" element={<Login />} /> */}

      <Route path="/adminpage" element={<AdminPage />} />
      <Route path="/pcpage" element={<PcPage />} />
      <Route path="/access-denied" element={<Page403 />} />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );
}

export default RenderRouters;
