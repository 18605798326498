/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Checkbox, CheckboxProps, Pagination, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import ArrowDownRightIconComponent from '../../assets/icons/ArrowDownRightIcon';
import ItemCardComponent from '../../components/item-component/item-card-component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HomeService from '../../services/home';



function SearchProductForm() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const cateId = searchParams.get('scat_id') || '';
    const query = searchParams.get('q') || '';
    const min = searchParams.get('min') || 0;
    const max = searchParams.get('max') || 1000000000;
    const [category, setCategory] = useState([]);
    const [productManufacturers, setProductManufacturers] = useState([]);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState();


    const onChange: CheckboxProps['onChange'] = (e) => {
        // console.log(`checked = ${e.target.checked}`);
    }

    const selectedValue = searchParams.get('priceRange') || null;

    const onChangePrice = (value: string, minValue: number, maxValue: number) => {
        // setMin(minValue);
        // setMax(maxValue);
        window.location.href = `/tim-kiem?scat_id=${cateId}&q=${query}&min=${minValue * 1000}&max=${maxValue * 1000}&priceRange=${value}`;
    };
    const handlePriceChange = (value: string) => {
        switch (value) {
            case 'under200':
                onChangePrice('under200', 0, 200);
                break;
            case '200to500':
                onChangePrice('200to500', 200, 500);
                break;
            case '500to800':
                onChangePrice('500to800', 500, 800);
                break;
            case '800to1000':
                onChangePrice('800to1000', 800, 1000);
                break;
            case '1000to1500':
                onChangePrice('1000to1500', 1000, 1500);
                break;
            case '1500to2000':
                onChangePrice('1500to2000', 1500, 2000);
                break;
            case '2000to3000':
                onChangePrice('2000to3000', 2000, 3000);
                break;
            case 'over3000':
                onChangePrice('over3000', 3000, Infinity);
                break;
            default:
                break;
        }
    };

    const fetchSearchByParam = async () => {
        const param = {
            categoryId: cateId,
            q: query,
            min: min,
            max: max,
            page: 1
        };

        try {
            const { data } = await HomeService.getAllProductBySearch(param);
            if (data.message === 'success') {
                // console.log(data.data, 'data search');
                setCategory(data.data.categories);
                setProductManufacturers(data.data.productManufacturers);
                setProducts(data.data.products.data);
                setTotal(data.data.products.total)
            }
        } catch (error) {
            console.error('Error fetching search data:', error);
        }
    };

    useEffect(() => {
        fetchSearchByParam();
    }, []);

    return (
        <>
            <div className='container mx-auto xxxxl:w-full xxs:w-300 '>
                <div className='py-3.5 px-4 sm:-mx-4'>
                    <Breadcrumb
                        separator=">"
                        items={[
                            {
                                title: <a href="" className="hover:!text-colorPrimary hover:!bg-white">Trang chủ</a>,
                            },
                            {
                                title: <a href="" className="hover:!text-colorPrimary hover:!bg-white">Tìm kiếm "{query}"</a>,
                            }
                        ]}
                    />
                </div>
            </div>
            <div className='container mx-auto bg-white xxs:w-300 xxxxl:w-full'>

                <div className='flex flex-wrap mt-4 bg-white'>
                    <div className='lg2:w-1/5 w-full h-full  '>
                        <div className='  py-2.5 bg-white px-4'>
                            <div className='mb-2.5 overflow-hidden sm:block hidden'>
                                <div className='uppercase font-semibold text-center border border-gray py-1.5 mb-2.5'>Lọc sản phẩm</div>
                                <div className='border-b border-b-gray mb-1.5 font-semibold uppercase leading-9'>Danh mục tìm thấy</div>
                                <div className='my-2'>
                                    {category.map((cate: any, index: number) => {
                                        return (
                                            <>
                                                <div key={cate.id} className='border-b border-b-gray border-dashed mb-1.5 font-semibold lg2:leading-9 lg2:text-base text-sm flex items-center cursor-pointer'>
                                                    <ArrowDownRightIconComponent style='w-4 h-4 fill-black mr-2' />
                                                    {cate.categoryName}
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                                {productManufacturers.length > 0 &&
                                    <div className='mb-4'>
                                        <div className='border-b border-b-gray mb-1.5 font-semibold leading-9 uppercase'>Hãng sản xuất</div>
                                        {productManufacturers.map((prodManu: any, index: number) => {
                                            return (
                                                <div key={prodManu.id}>

                                                    <Checkbox onChange={onChange}>{prodManu.prdManufacturerName}</Checkbox>
                                                </div>
                                            )
                                        })}

                                    </div>
                                }

                                <div className='mb-4 flex flex-col'>
                                    <div className='border-b border-b-gray mb-1.5 font-semibold leading-9 uppercase'>Khoảng giá</div>
                                    <Checkbox
                                        checked={selectedValue === 'under200'}
                                        onChange={() => onChangePrice('under200', 0, 200)}
                                    >
                                        Dưới 200kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '200to500'}
                                        onChange={() => onChangePrice('200to500', 200, 500)}
                                    >
                                        200kw - 500kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '500to800'}
                                        onChange={() => onChangePrice('500to800', 500, 800)}
                                    >
                                        500kw - 800kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '800to1000'}
                                        onChange={() => onChangePrice('800to1000', 800, 1000)}
                                    >
                                        800kw - 1.000kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '1000to1500'}
                                        onChange={() => onChangePrice('1000to1500', 1000, 1500)}
                                    >
                                        1.000kw - 1.500kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '1500to2000'}
                                        onChange={() => onChangePrice('1500to2000', 1500, 2000)}
                                    >
                                        1.500kw - 2.000kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === '2000to3000'}
                                        onChange={() => onChangePrice('2000to3000', 2000, 3000)}
                                    >
                                        2.000kw - 3.000kw
                                    </Checkbox>
                                    <Checkbox
                                        checked={selectedValue === 'over3000'}
                                        onChange={() => onChangePrice('over3000', 3000, Infinity)}
                                    >
                                        Trên 3.000kw
                                    </Checkbox>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='lg2:w-4/5 w-full h-full  '>
                        <div className='bg-white px-4'>
                            <div className='py-4 flex justify-between items-center text-sm border-b border-gray'>
                                <div className='sm:flex hidden items-center font-semibold sm:text-base text-xs'>
                                    Tìm thấy
                                    <div className='text-colorSecondary mx-1 sm:text-base text-xs'>{total}</div>
                                    sản phẩm
                                </div>
                                <div className='flex flex-wrap'>
                                    {productManufacturers.length > 0 &&
                                        <>
                                            <div className='mr-2 mb-2 sm:hidden block'>
                                                <Select
                                                    showSearch
                                                    // className='w-52'
                                                    placeholder="Hãng"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={productManufacturers.map((prodManu: any) => ({
                                                        value: prodManu.id,
                                                        label: prodManu.prdManufacturerName,
                                                    }))}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className='mr-2 mb-2 sm:hidden block'>
                                        <Select
                                            className='w-full'
                                            placeholder="Chọn khoảng giá"
                                            value={selectedValue}
                                            onChange={handlePriceChange}
                                            options={[
                                                { value: 'under200', label: 'Dưới 200kw' },
                                                { value: '200to500', label: '200kw - 500kw' },
                                                { value: '500to800', label: '500kw - 800kw' },
                                                { value: '800to1000', label: '800kw - 1.000kw' },
                                                { value: '1000to1500', label: '1.000kw - 1.500kw' },
                                                { value: '1500to2000', label: '1.500kw - 2.000kw' },
                                                { value: '2000to3000', label: '2.000kw - 3.000kw' },
                                                { value: 'over3000', label: 'Trên 3.000kw' },
                                            ]}
                                        />
                                    </div>
                                    {/* <div className='sm:mr-0 sm:mb-0 mr-2 mb-2'>
                                        <Select
                                            showSearch
                                            // className='w-52'
                                            placeholder="Sắp xếp theo"
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                                            }
                                            options={[
                                                {
                                                    value: '1',
                                                    label: 'Tất cả',
                                                },
                                                {
                                                    value: '2',
                                                    label: 'Mới nhất',
                                                },
                                                {
                                                    value: '3',
                                                    label: 'Giá tăng dần',
                                                },
                                                {
                                                    value: '4',
                                                    label: 'Giá giảm dần',
                                                },
                                                {
                                                    value: '5',
                                                    label: 'Lượt xem',
                                                },
                                                {
                                                    value: '6',
                                                    label: 'Trao đổi',
                                                },
                                                {
                                                    value: '7',
                                                    label: 'Đánh giá',
                                                },
                                                {
                                                    value: '8',
                                                    label: 'Tên từ A - Z',
                                                },
                                            ]}
                                        />
                                    </div> */}
                                </div>

                            </div>
                            <div className='flex flex-wrap -mx-2'>
                                {products.map((product: any, index: number) => {
                                    const specification = product.specification;
                                    const arraySpecification = specification.split('|');
                                    const promotion = product.promotion;
                                    const arrayPromotion = promotion !== '' && promotion.split('|');
                                    const img = product.logo;
                                    const arrayImg = img.split('|');
                                    const fixedPrice = product.fixedPrice;
                                    const price = product.price;
                                    let discount: number;
                                    if (product.fixedPrice < product.price) {
                                        discount = 0;
                                    } else {
                                        discount = parseFloat((((product.fixedPrice - product.price) / product.fixedPrice) * 100).toFixed(2));
                                    }
                                    return (
                                        <div key={product.id}>
                                            <ItemCardComponent
                                                id={product.id}
                                                title={product.prodName}
                                                slug={product.prodNameSlug}
                                                image={arrayImg[0]}
                                                price={price}
                                                fixedPrice={fixedPrice}
                                                discount={discount}
                                                guarantee={product.quarantee}
                                                desc={arraySpecification}
                                                promotion={arrayPromotion}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <Pagination align="end" defaultCurrent={1} total={total} className='py-4' />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SearchProductForm
