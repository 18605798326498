import React from 'react'

interface IConProps {
    style?: string;
}

const PcAcessIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">

            <defs>
            </defs>

            <g id="pc-system">

                <path d="M34,7a1,1,0,1,1-1-1A1,1,0,0,1,34,7Z" />

                <line className="cls-1" x1="18.5858" x2="21.4142" y1="11.5858" y2="14.4142" />

                <line className="cls-1" x1="21.4142" x2="18.5858" y1="11.5858" y2="14.4142" />

                <line className="cls-1" x1="52.773" x2="11.1774" y1="44" y2="44" />

                <polyline className="cls-1" points="9 27.814 9 21 55 21 55 27.788" />

                <rect className="cls-1" height="22" width="9" x="2" y="28" />

                <line className="cls-1" x1="39" x2="25" y1="48" y2="48" />

                <circle className="cls-1" cx="6.5" cy="34.5" r="1.5" />

                <circle className="cls-1" cx="6.5" cy="42.5" r="3.5" />

                <rect className="cls-1" height="22" width="9" x="53" y="28" />

                <circle className="cls-1" cx="57.5" cy="34.5" r="1.5" />

                <circle className="cls-1" cx="57.5" cy="42.5" r="3.5" />

                <polygon className="cls-1" points="37 57 17 57 18 51 36 51 37 57" />

                <path className="cls-1" d="M45.5,53h0A2.5,2.5,0,0,1,48,55.5V56a0,0,0,0,1,0,0H43a0,0,0,0,1,0,0v-.5A2.5,2.5,0,0,1,45.5,53Z" />

                <polygon className="cls-1" points="45 40 39 40 39 29 42 27 45 29 45 40" />

                <path d="M43,31a1,1,0,1,1-1-1A1,1,0,0,1,43,31Z" />

                <path className="cls-1" d="M38,21V19a2,2,0,0,1,2-2h0a2,2,0,0,1,2,2v8" />

                <line className="cls-1" x1="20" x2="34" y1="54" y2="54" />

                <line className="cls-2" x1="31" x2="31" y1="51" y2="57" />

                <circle className="cls-1" cx="43" cy="11" r="2" />

                <line className="cls-2" x1="35" x2="35" y1="44" y2="48" />

                <line className="cls-2" x1="29" x2="29" y1="44" y2="48" />

            </g>

        </svg>
    )
}

export default PcAcessIconComponent