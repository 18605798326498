import React from 'react'

interface IConProps {
    style?: string;
}

const ArrowDownRightIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" id="memory-arrow-down-right"><path d="M19 12V14H18V15H17V16H16V17H15V18H13V16H14V15H15V14H11V13H9V12H8V10H7V3H9V9H10V11H12V12H15V11H14V10H13V8H15V9H16V10H17V11H18V12" /></svg>
    )
}

export default ArrowDownRightIconComponent