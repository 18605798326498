/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, Switch, Upload, notification, Flex, TableColumnsType, TransferProps, GetProp, TableProps, Tag, Transfer } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import ProductService from '../../../../services/product';
import NewsService from '../../../../services/news';
import { env } from 'process';
import EyeIconComponent from '../../../../assets/icons/EyeIcon';

interface Item {
    key: string;
    id:number;
    categoryName: string;
    categoryNameSlug: string;
    parentId: number;
    logo: File;
    status: string;
    subcategory:string;
    isShowHome: boolean;
    isShowMenu: boolean;
}

interface Subcategory {
    id: number;
    categoryName: string;
  }

  interface DataType {
    key: string;
    title: string;
    description: string;
    tag: string;
  }


  type TransferItem = GetProp<TransferProps, 'dataSource'>[number];
type TableRowSelection<T extends object> = TableProps<T>['rowSelection'];
  interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: DataType[];
    leftColumns: TableColumnsType<DataType>;
    rightColumns: TableColumnsType<DataType>;
  }

const ManageItemsCollection
: React.FC<{ title: string }> = ({ title }) => {
    // const [data, setData] = useState<Item[]>([]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<Item | null>(null);
    const [form] = Form.useForm();

    const [listCollection, setListCollection] = useState<Item[]>([]);

    const [categoryById, setCategoryById] = useState<Item>();

    const getAllCollection = async () => {
        const result = await ProductService.getAllCollection();
    
        if (result.data.message === 'success') {
        const formattedData = await Promise.all(
            result.data.data.map(async (collection: any, index: number) => {

                // if (category.parentId) {
                //     await getCategoryById(category.parentId);
                //     subcategoryName = categoryById?.categoryName ? categoryById?.categoryName : "";
                // }
                return {
                    key: collection.id || index.toString(),
                    name: collection.name,
                    logo: collection.logo,
                    status: collection.status,
                };
            })
        );
          setListCollection(formattedData);
        }
      };


      const getCategoryById = async (id: number) => {
        const result = await ProductService.getCategoryById(id);
        if (result.response?.data.message === 'success') {
          setCategoryById(result.response?.data.data);
        }
      };

      useEffect(() => {
        getAllCollection();
        getAllProduct();
      }, []);

    //   console.log("listCate", listCate)
      const [api, contextHolder] = notification.useNotification();




    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = async (record: any) => {
        const result = await ProductService.getCollectionById(record.key);
        setFileName(record.logo);

        const logoFileList = [
            {
                uid: '-1',
                name: record.logo?.split('/').pop(),
                status: 'done',
                url: `https://api.lehieucomputer.com${record.logo}`,
            }
        ];
        // console.log("logoFileList", logoFileList)
        setIsModalOpen(true);
        setEditingItem(result.response?.data.data);
        form.setFieldsValue({...result.response?.data.data,logo: logoFileList});
    };

    const handleDelete = async (key: number) => {
        // console.log("record", record)
        // setListCate(listCate.filter(item => item.key !== key));
        const obj = {
            id: key
        }
        const result = await ProductService.deleteCollection(obj);
        if (result.response?.data.message === "success") {
            api.success({
                message: 'Xóa bộ sưu tập thành công',
                showProgress: true,
                pauseOnHover: true,
            }); 
            window.location.reload();
        }
    };

    const [addCollection, setAddCollection] = useState()
    const [allProduct, setAllProduct] = useState<any[]>([])

    const { REACT_APP_API_URL } = process.env;
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
                values.logo = `${fileName}`;
                // console.log("logo",  values.logo)
                if (editingItem) {
                    const newValues = {
                        ...values,
                        id:editingItem.id,
                        name: values.name,
                        logo: `${fileName}` || "",
                        status: values.status,
                    };
                    // console.log("newValues", newValues)
                    const result = await ProductService.updateCollection(newValues);
    
                        if (result.response?.data.message === "success") {
                            api.success({
                                message: 'Update bộ sưu tập thành công',
                                showProgress: true,
                                pauseOnHover: true,
                              }); 
                              // console.log("result", result)
                              // window.location.reload();
                        }
                        setIsModalOpen(false);
                } else {
                    const newValues = {
                        ...values,
                        name : values.name || "",
                        logo:`${fileName}` || "",
                        status:values.status || "",
                    };
                
                                const result = await ProductService.createCollection(newValues);
                                if (result.response) {
                                    setAddCollection(result.response.data.data);
                                    api.success({
                                        message: 'Thêm bộ sưu tập thành công',
                                        showProgress: true,
                                        pauseOnHover: true,
                                    }); 
                                    window.location.reload();
                                }
    
                    setIsModalOpen(false);
                }
            
        } catch (error) {
            api.error({
                message: 'Vui lòng điền đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    
    };
    const [isModalVisible, setIsModalVisible] = useState(false);

    const getAllProduct = async ()=>{
      const result = await ProductService.getAllProduct();
    
        if (result.data.message === 'success') {
        setAllProduct(result?.data.data);
        }
    }


    const [collectionProducts, setCollectionProducts] = useState<string[]>([]);
    

    const columns= [
        {
            title: 'Tên bộ sưu tập',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            render: (logo: any) => (
                <img
                    src={`https://api.lehieucomputer.com${logo}`}
                    alt="Logo"
                    style={{ width: '100px', height: 'auto' }}
                />
            ),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status : any) => (
                <span style={{ color: status === 'Active' ? 'green' : 'red', fontWeight: 'bold' }}>
                    {status === 'Active' ? 'Hoạt động' : 'Không hoạt động'}
                </span>
            ),
        },
        {
            title: 'Thêm sản phẩm',
            key: 'addProduct',
            render: (_: any, record: any) => (
                <span className=''>
                    <Button type="link" onClick={() => handleAddProduct(record)}>
                        <EyeIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                </span>
                
            ),
        },
        {
            title: 'Sửa/Xóa',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link" onClick={() => handleEdit(record)}>
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record?.key)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const [targetKeys, setTargetKeys] = useState<TransferProps['targetKeys']>([]);
    const [collectionById, setCollectionById] = useState<any>();
    const handleAddProduct = async (record:any) => {
      setIsModalVisible(true);
      const result = await ProductService.getCollectionById(record.key);
      const collectionKeys = result?.response?.data.data.products.map((item: any) => String(item.id));
      setCollectionProducts(collectionKeys);
      setTargetKeys(collectionKeys);
      setCollectionById(result?.response?.data.data)
    };

    const { Option } = Select;

    const [availableSubcategories, setAvailableSubcategories] = useState([]);

    const [fileName,setFileName] = useState("")

    const handleUpload = async (options: any) => {
        const { file, onSuccess } = options;

        const formData = new FormData();
        formData.append('file', file);

        const result = await NewsService.uploadFileNewsCMS(formData);

        if (result.response) {
            onSuccess(result.response.data);
            setFileName(result.response.data.data);
        }
    };

const mockData = allProduct?.map<any>((item:any, index:number) => ({
  key: item.id.toString(),
  id:item.id,
  title: `${item.prodName}`,
  description: `description of content${index + 1}`,
}));


const columnsAdd: TableColumnsType<DataType> = [
  {
    dataIndex: 'title',
    title: 'Tên sản phẩm',
  },
];


const handleCloseModal = async () => {
  setIsModalVisible(false);
  const numericProducts = targetKeys?.map((item: any) => Number(item));

    const objectAdd = {
      id:collectionById?.id,
      productIds: numericProducts,
    }
    // console.log("objectAdd", objectAdd)
    const result = await ProductService.addProductToCollection(objectAdd);
    if (result.response?.data.message === "success") {
      api.success({
          message: 'Update sản phẩm từ bộ sưu tập thành công',
          showProgress: true,
          pauseOnHover: true,
      }); 
      window.location.reload();
  }
  }
const handleCancel = () => {
  setIsModalOpen(false);
};

const handleCancelModal = () => {
  setIsModalVisible(false);
}

  // const [disabled, setDisabled] = useState(false);

  const onChange: TableTransferProps['onChange'] = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };


  const filterOption = (input: string, item: DataType) =>
    item.title?.includes(input) || item.tag?.includes(input);

  const TableTransfer: React.FC<TableTransferProps> = (props) => {
    const { leftColumns, rightColumns, ...restProps } = props;
    return (
      <Transfer style={{ width: '100%' }} {...restProps}>
        {({
          direction,
          filteredItems,
          onItemSelect,
          onItemSelectAll,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {
          const columns = direction === 'left' ? leftColumns : rightColumns;
          const rowSelection: TableRowSelection<TransferItem> = {
            getCheckboxProps: () => ({ disabled: listDisabled }),
            onChange(selectedRowKeys) {
              onItemSelectAll(selectedRowKeys, 'replace');
            },
            selectedRowKeys: listSelectedKeys,
            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
          };
  
          return (
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              size="small"
              style={{ pointerEvents: listDisabled ? 'none' : undefined }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) {
                    return;
                  }
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>
    );
  };
     
    // console.log("fileName", fileName)

    return (
        <div>
            {contextHolder}
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm bộ sưu tập
            </Button>
            <Table columns={columns} dataSource={listCollection} />

            <Modal title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label="Tên bộ sưu tập" rules={[{ required: true, message: 'Hãy nhập tên bộ sưu tập!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="logo"
                        label="Logo"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                        }}
                        rules={[{ required: true, message: 'Hãy nhập logo!' }]}
                    >
                        <Upload
                        name="logo"
                        listType="picture"
                        customRequest={handleUpload}
                        maxCount={1}
                        >
                        <Button>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name="status" label="Trạng thái" rules={[{ required: true, message: 'Hãy nhập trạng thái!' }]}>
                                <Select placeholder="Chọn trạng thái">
                                    <Option value="Active">Active</Option>
                                    <Option value="Deactivate">Deactivate</Option>
                                </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                        title="Thêm sản phẩm vào bộ sưu tập"
                        open={isModalVisible}
                        onOk={handleCloseModal}
                        onCancel={handleCancelModal}
                        width={1000}
                    >
                        
                        <Flex align="start" gap="middle" vertical>
                        <TableTransfer
                            dataSource={mockData}
                            targetKeys={targetKeys}
                            showSearch
                            showSelectAll={false}
                            onChange={onChange}
                            filterOption={filterOption}
                            leftColumns={columnsAdd}
                            rightColumns={columnsAdd}
                        />
                        </Flex>
            </Modal>
        </div>
    );
};

export default ManageItemsCollection
;
