/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const getListAppUserCMS = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminAppUser/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const createAppUserCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminAppUser/create`, obj);
        if (response) {
            return {
                response,
                message: "Create User successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateAppUserCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminAppUser/update`, obj);
        if (response) {
            return {
                response,
                message: "Update User successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteAppUserCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminAppUser/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete User successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};


const UserCMSService = {
    getListAppUserCMS,
    createAppUserCMS,
    updateAppUserCMS,
    deleteAppUserCMS,
};

export default UserCMSService;
