import { createContext, useCallback, useEffect, useState } from "react";
import { IAuth } from "../interfaces";
import { UserService } from "../services";

const AuthContext = createContext({});

interface IProps {
  children: React.ReactNode;
}

const AuthContextProvider = ({ children }: IProps) => {
  const [auth, setAuth] = useState<IAuth | undefined>({
    user: undefined,
    access_token: localStorage.getItem("token") || "",
    loading: true,
  });

  const fetchProfile = useCallback(async (token: string) => {
    const response = await UserService.me();
    // console.log(11, response)
    if (response && response.data) {
      setAuth((pre: any) => {
        return {
          ...pre,
          access_token: token,
          user: { ...response.data },
        };
      });
    }
  }, []);

  // console.log(22, fetchProfile)

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchProfile(token)
        .catch(console.error)
        .finally(() => {
          setAuth((pre: any) => {
            return {
              ...pre,
              loading: false,
            };
          });
        });
    }
  }, [fetchProfile]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
