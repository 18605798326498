/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const getListFAQCMS = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminFAQs/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const createFAQCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminFAQs/create`, obj);
        if (response) {
            return {
                response,
                message: "Create FAQs successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateFAQCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminFAQs/update`, obj);
        if (response) {
            return {
                response,
                message: "Update FAQs successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const deleteFAQCMS = async (id: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminFAQs/delete`, id);
        if (response) {
            return {
                response,
                message: "Delete FAQs successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};


const FAQCMSService = {
    getListFAQCMS,
    createFAQCMS,
    updateFAQCMS,
    deleteFAQCMS,
};

export default FAQCMSService;
