import type { FormProps } from "antd";
import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  notification,
} from "antd";
import UserDetailIconComponent from "../../assets/icons/UserDetailIcon";
import PasswordIconComponent from "../../assets/icons/PasswordIcon";
import imgLogo from "../../assets/img/ImgLogoLogin.png";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import DetailsPC from "../../components/details-pc/details-pc";

type FieldType = {
  email?: string;
  password?: string;
  rePassword?: string;
  //   remember?: boolean;
  fullname?: string;
  address?: string;
  phone?: string;
};

const TechNews = () => {
  console.log("hello");
  const { slug } = useParams<{
    slug: string;
  }>();

  console.log("Slug:", slug);
  // console.log("ID:", id);
  return (
    <div className="container mx-auto xxxxl:w-full xxs:w-300 ">
      <DetailsPC prodNameSlug={slug} />
    </div>
  );
};

export default TechNews;
