/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/style-prop-object */
import { Drawer, Input, Popover, Select, Space } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import ArrowDownIconComponent from '../../assets/icons/ArrowDownIcon'
import UserIconComponent from '../../assets/icons/UserIcon'
import CartIconComponent from '../../assets/icons/CartIcon'
import PhoneIconComponent from '../../assets/icons/PhoneIcon'
import { useNavigate } from 'react-router-dom'
import UserPenIconComponent from '../../assets/icons/UserPenIcon'
import CommentIconComponent from '../../assets/icons/CommentIcon'
import HomeService from '../../services/home'
import logo from '../../assets/imgs/logoNgang.png';
import logo2 from '../../assets/imgs/lhlogo.png';
import TabSidebarIconComponent from '../../assets/icons/TabSidebarIcon'
import ArrowRight from '../../assets/icons/ArrowRight'
import LaptopIconComponent from '../../assets/icons/LaptopIcon'
import PcIconComponent from '../../assets/icons/PcIcon'
import TabletPenIconComponent from '../../assets/icons/TabletPenIcon'
import LaptopAppleIconComponent from '../../assets/icons/LaptopAppleIcon'
import SmartPhoneIconComponent from '../../assets/icons/SmartPhoneIcon'
import PcAcessIconComponent from '../../assets/icons/PcAcessIcon'
import ElectronAssensorIconComponent from '../../assets/icons/ElectronAssensorIcon'
import CPUIconComponent from '../../assets/icons/CPUIcon'
import SimIconComponent from '../../assets/icons/SimIcon'
import RepairIconComponent from '../../assets/icons/RepairIcon'
import UserManually from '../../assets/icons/UserManuallyIcon'
import SliderTextComponent from '../../components/slider/slider-text-component'
const { Search } = Input;
const { Option } = Select;


function HeaderFormComponent() {

    const [listCate, setListCate] = useState([]);
    const navigate = useNavigate();
    const [isFixed, setIsFixed] = useState(false);
    const [hoveredCategory, setHoveredCategory] = useState<any>(null);
    const [cateId, setCateId] = useState('');
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [min, setMin] = useState();
    const [max, setMax] = useState();
    const [page, setPage] = useState();
    const [query, setQuery] = useState('');

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openSubCate, setOpenSubCate] = useState(false);
    const [subCategory, setSubCategory] = useState<any>(null);
    const [openSubSubCate, setOpenSubSubCate] = useState(false);
    const [subSubCategory, setSubSubCategory] = useState<any>(null);

    const showLoading = () => {
        setOpen(true);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const showSubCateMenu = (subCate: any) => {
        setSubCategory(subCate);
        setOpenSubCate(true);
    };

    const handleCloseSubCateMenu = () => {
        setOpenSubCate(false);
    };

    const showSubSubCateMenu = (subCate: any) => {
        setSubSubCategory(subCate);
        setOpenSubSubCate(true);
    };

    const handleCloseSubSubCateMenu = () => {
        setOpenSubSubCate(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fetchCategories = async () => {
        const result = await HomeService.getAllCategory();

        if (result.data.message === 'success') {
            setListCate(result.data.data);
            // console.log(result.data.data, 'data')
        }
    };


    useEffect(() => {
        const updateTotalQuantity = () => {
            const total = localStorage.getItem('totalQuantity');
            const quantity = (total !== null && !Number.isNaN(parseInt(total, 10))) ? parseInt(total, 10) : 0;

            setTotalQuantity(quantity);
        };

        updateTotalQuantity();

        window.addEventListener('storage', updateTotalQuantity);

        return () => {
            window.removeEventListener('storage', updateTotalQuantity);
        };
    }, []);

    useEffect(() => {

        fetchCategories();
    }, []);

    const handleMouseEnter = (cate: any) => {
        setHoveredCategory(cate);
    };

    const handleMouseLeave = () => {
        setHoveredCategory(null);
    };

    const handleClick = (path: string, id: number) => {
        navigate(`/danh-muc/${path}-${id}`);
    };
    const handleClickSubCate = (obj: any) => {
        const parentPath = encodeURIComponent(obj.parentPath);
        const parentId = encodeURIComponent(obj.parentId);
        const childPath = encodeURIComponent(obj.childPath);
        const childId = encodeURIComponent(obj.childId);

        const path = `danh-muc/${parentPath}-${parentId}/${childPath}-${childId}`;
        navigate(path);
    };

    const handleSelectChange = (value: any) => {
        // console.log(`Selected: ${value}`);
        setCateId(value);
    };
    const selectBefore = (
        <Select defaultValue="Tất cả danh mục" onChange={handleSelectChange} style={{ minWidth: 150, textAlign: 'left' }}>
            <Option value=''>Tất cả danh mục</Option>
            {listCate.map((category: any) => {
                return (
                    <Option key={category.id} value={category.id}>
                        {category.categoryName}
                    </Option>
                );
            })}
        </Select>
    );

    const content = (

        <div className='-m-3 min-w-75' onMouseLeave={() => handleMouseLeave()}>
            {listCate.map((cate: any) => (
                <a
                    key={cate.id}
                    className="cursor-pointer flex items-center py-3 px-5 hover:bg-navMenu relative "
                    onMouseEnter={() => handleMouseEnter(cate)}
                    href={`/danh-muc/${cate.categoryNameSlug}`}
                >
                    {cate.categoryName === "Laptop"
                        ?
                        <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-1.5'>
                            <LaptopIconComponent style='w-5 h-5 stroke-black fill-none' />
                        </div>
                        :
                        cate.categoryName === "PC"
                            ?
                            <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                <PcIconComponent style='w-7 h-7 fill-black ' />
                            </div>
                            :
                            cate.categoryName === "Macbook"
                                ?
                                <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                    <LaptopAppleIconComponent style='w-6 h-6 fill-black' />
                                </div>
                                :
                                cate.categoryName === "Máy tính bảng"
                                    ?
                                    <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                        <TabletPenIconComponent style='w-5 h-5 stroke-black fill-none' />
                                    </div>
                                    :
                                    cate.categoryName === "Điện thoại"
                                        ?
                                        <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                            <SmartPhoneIconComponent style='w-6 h-6 stroke-2 fill-black' />
                                        </div>
                                        :
                                        cate.categoryName === "Linh kiện"
                                            ?
                                            <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                <PcAcessIconComponent style='w-8 h-8 stroke-black stroke-2 fill-none' />
                                            </div>
                                            :
                                            cate.categoryName === "Phụ kiện điện tử"
                                                ?
                                                <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                    <CPUIconComponent style='w-7 h-7 fill-black' />
                                                </div>
                                                :
                                                cate.categoryName === "Linh kiện PC"
                                                    ?
                                                    <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                        <PcAcessIconComponent style='w-8 h-8 stroke-black stroke-2 fill-none' />
                                                    </div>
                                                    :
                                                    cate.categoryName === "Sim + Wifi"
                                                        ?
                                                        <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                            <SimIconComponent style='w-6 h-6 fill-black' />
                                                        </div>
                                                        :
                                                        cate.categoryName === "Sửa chữa"
                                                            ?
                                                            <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                <RepairIconComponent style='w-6 h-6 fill-black' />
                                                            </div>
                                                            : ''
                    }
                    <div className=' text-center uppercase font-semibold ml-2'>
                        {cate.categoryName}
                    </div>
                </a>
            ))}

            {hoveredCategory && hoveredCategory.subcategories.length > 0 && (
                <div className="absolute bg-white shadow-lg p-2 w-125 h-110 -right-125 top-0 flex">
                    <div className='flex '>
                        {hoveredCategory.subcategories.map((sub: any) => {

                            return (
                                <div className='flex flex-col items-center'>
                                    <a key={sub.id} className="p-4 text-colorThird text-lg font-semibold duration-300 cursor-pointer"
                                        href={`/danh-muc/${sub.categoryNameSlug}`}
                                    >
                                        {sub.categoryName}
                                    </a>
                                    {sub.subcategories.map((subCate: any) => {
                                        return (
                                            <>
                                                <a key={subCate.id} href={`/danh-muc/${subCate.categoryNameSlug}`}
                                                    className="p-2 hover:text-colorThird font-semibold duration-300 cursor-pointer"
                                                >
                                                    {subCate.categoryName}
                                                </a>
                                            </>
                                        )
                                    })}
                                </div>
                            );
                        })}
                    </div>

                </div>
            )}
        </div>

    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const handleSearch = () => {
        if (query !== '') {
            window.location.href = `/tim-kiem?scat_id=${cateId}&q=${query}`;
        }

    };

    return (
        <div className=' bg-colorSecondary/80 '>
            <div className='container mx-auto flex items-center xs:w-150 justify-center'>
                {/* <div className=''> */}
                <SliderTextComponent />
                {/* </div> */}
            </div>
            <div className='md:bg-colorSecondary/10 bg-white'>
                <div className='container mx-auto'>
                    <div className={` md:flex hidden items-center flex-wrap justify-between py-2 xs:px-0 px-4`}>
                        <div className='flex items-center xl:w-2/3 lg:w-1/2 sm:w-1/3 w-full justify-between sm:order-1 order-2'>
                            {/* <a href="/" className=' w-2/5 xl:flex hidden sm:justify-center justify-start'>
                            <img src={logo} alt="Lê Hiếu Computer" className='sm:h-28 xs:h-24 h-15' />
                        </a> */}
                            <Popover placement="bottomLeft" title={''} content={content} arrow={false}
                            // trigger="click"
                            >
                                <div className="inline-flex cursor-pointer uppercase justify-center items-center rounded-md shadow-sm md:px-4 px-2 py-2.5 w-100 bg-white mr-2">
                                    <div className='md:text-sm text-2xs font-semibold text-colorSecondary'>
                                        Danh mục sản phẩm
                                    </div>

                                    <ArrowDownIconComponent style='-mr-1 md:ml-2 ml-0 md:h-5 md:w-5 w-3.5 h-3.5 fill-colorSecondary' />
                                </div>
                            </Popover>
                            <div className='xl:block hidden rounded-lg w-full'>
                                <Space.Compact className='w-full'>
                                    <Search
                                        // addonBefore={selectBefore}
                                        placeholder="Tìm kiếm sản phẩm..."
                                        allowClear
                                        size='large'
                                        value={query}
                                        onChange={handleSearchChange}
                                        onSearch={handleSearch}
                                    />
                                </Space.Compact>
                            </div>
                            <div className='xl:hidden lg:block hidden rounded-lg w-full'>
                                <Space.Compact className='w-full'>
                                    <Search
                                        placeholder="Tìm kiếm sản phẩm..."
                                        allowClear size='large'
                                        value={query}
                                        onChange={handleSearchChange}
                                        onSearch={handleSearch}
                                    />
                                </Space.Compact>
                            </div>
                            <div className='sm:hidden block rounded-lg w-full'>
                                <Space.Compact className='w-full'>
                                    <Search
                                        placeholder="Tìm kiếm sản phẩm..."
                                        allowClear size='middle'
                                        value={query}
                                        onChange={handleSearchChange}
                                        onSearch={handleSearch}
                                    />
                                </Space.Compact>
                            </div>
                        </div>

                        <div className='sm:pl-8 pl-4 flex-1 lg2:w-1/3 lg:w-1/2 sm:w-2/3 w-full sm:order-2 order-1 sm:mb-0 mb-2'>
                            <div className=' lg:py-2.5 py-1 bg-colorSecondaryHigher rounded-full flex justify-between'>
                                <div className=' -ml-4 flex items-center relative'>
                                    <div className='xs:mr-2 mr-0.5 lg:w-11 lg:h-11 w-8 h-8 bg-white rounded-full flex items-center justify-center'>
                                        <PhoneIconComponent style='stroke-colorSecondary lg:w-6 lg:h-6 w-4 h-4' />

                                    </div>
                                    <div className='text-white'>
                                        <div className='font-medium block lg:text-sm xs:text-xs text-2xs'>Hotline</div>
                                        <div className='font-semibold lg:text-sm xs:text-xs text-2xs'>010-6222-1678</div>
                                    </div>
                                </div>
                                <a href='/dang-ky' className=' flex items-center relative pr-4 cursor-pointer'>
                                    <div className='mr-2 lg:w-11 lg:h-11 w-8 h-8 rounded-full flex items-center justify-center bg-white'>
                                        <UserIconComponent style='lg:w-6 lg:h-6 w-4 h-4 stroke-colorSecondary fill-none' />
                                    </div>
                                    <div className='text-white'>
                                        <div className='font-medium lg:text-sm xs:text-xs text-2xs'>Thành viên</div>
                                    </div>
                                </a>
                                <a href='/order' className=' flex items-center relative xs:pr-4 pr-2 cursor-pointer'>
                                    <div className='lg:w-5 lg:h-5 w-3.5 h-3.5 absolute top-0 lg:left-7 left-5 lg:text-xs text-2xs flex items-center justify-center bg-colorThird text-white rounded-full font-semibold'>{totalQuantity > 9 ? '9+' : totalQuantity}</div>
                                    <div className='xs:mr-2 mr-0.5 lg:w-11 lg:h-11 w-8 h-8 rounded-full flex items-center justify-center bg-white'>
                                        <CartIconComponent style='lg:w-6 lg:h-6 w-4 h-4 stroke-colorSecondary' />

                                    </div>
                                    <div className='text-white'>

                                        <div className='font-medium lg:text-sm xs:text-xs text-2xs'>Giỏ hàng</div>
                                    </div>
                                </a>
                                {/* <div className=' md:flex hidden items-center relative cursor-pointer'>
                                <div className='mr-2 lg:w-11 lg:h-11 w-8 h-8 rounded-full flex items-center justify-center bg-white'>
                                    <UserPenIconComponent style='lg:w-6 lg:h-6 w-4 h-4 fill-colorSecondary' />

                                </div>
                                <div className='text-white'>

                                    <div className='font-medium lg:text-sm text-xs'>Đăng ký</div>
                                </div>
                            </div>
                            <div className=' flex items-center relative xs:pr-4 pr-2 cursor-pointer'>
                                <div className='xs:mr-2 mr-0.5 lg:w-11 lg:h-11 w-8 h-8 rounded-full flex items-center justify-center bg-white'>
                                    <UserIconComponent style='lg:w-6 lg:h-6 w-4 h-4 fill-colorSecondary' />

                                </div>
                                <div className='text-white'>

                                    <div className='font-medium lg:text-sm xs:text-xs text-2xs'>Đăng nhập</div>
                                </div>
                            </div> */}

                            </div>
                        </div>
                    </div>


                    <div className='md:hidden flex items-center justify-between px-4 xs:-mx-4 py-4 bg-white'>
                        <div onClick={showLoading}>
                            <TabSidebarIconComponent style='w-6 h-6 fill-colorSecondary' />
                        </div>

                        <a href="/" className='w-32 flex items-center sm:justify-center justify-start'>
                            <img src={logo} alt="Lê Hiếu Computer" className='' />
                        </a>
                        <a href='/order' className=' relative cursor-pointer xs:pr-4 pr-2'>
                            <div className='py-0.5 px-1.5 absolute -top-1 left-3 text-2xs flex items-center justify-center bg-colorThird text-white rounded-full font-semibold'>{totalQuantity > 9 ? '9+' : totalQuantity}</div>
                            <CartIconComponent style='w-6 h-6 stroke-colorSecondary' />

                        </a>
                    </div>
                    <Drawer
                        closable={false}
                        destroyOnClose
                        title={<div className='text-black/30 text-sm text-center'>Menu</div>}
                        placement="left"
                        open={open}
                        loading={loading}
                        onClose={() => setOpen(false)}
                        width={250}
                    >
                        {listCate.map((cate: any) => (
                            <div
                                key={cate.id}
                                className="cursor-pointer flex justify-between items-center py-3 relative "
                            >
                                <a href={`/danh-muc/${cate.categoryNameSlug}`} className=' text-center uppercase font-semibold'>
                                    {cate.categoryName}
                                </a>
                                {cate.subcategories.length > 0 &&
                                    <div className='w-6 flex justify-center items-center' onClick={() => showSubCateMenu(cate)}>
                                        &#10095;
                                    </div>
                                }

                            </div>
                        ))}
                        <div
                            className="cursor-pointer flex justify-between items-center py-3 relative "
                        >
                            <a href={`/dang-ky`} className=' text-center uppercase font-semibold'>
                                Đăng ký thành viên
                            </a>
                        </div>

                    </Drawer>
                    <Drawer
                        closable
                        destroyOnClose
                        title={<div className='text-black/30 text-sm text-center'>{subCategory?.categoryName}</div>}
                        placement="left"
                        open={openSubCate}
                        onClose={handleCloseSubCateMenu}
                        width={250}
                        closeIcon={<div>&#10094;</div>}
                    >
                        {subCategory?.subcategories.map((subCate: any) => (
                            <div
                                key={subCate.id}
                                className="cursor-pointer flex justify-between items-center py-3 relative"
                            >
                                <a href={`/danh-muc/${subCate.categoryNameSlug}`} className='text-center uppercase font-semibold'>
                                    {subCate.categoryName}
                                </a>
                                {subCate.subcategories.length > 0 &&
                                    <div className='w-6 flex justify-center items-center' onClick={() => showSubSubCateMenu(subCate)}>
                                        &#10095;
                                    </div>
                                }
                            </div>
                        ))}
                    </Drawer>
                    <Drawer
                        closable
                        destroyOnClose
                        title={<div className='text-black/30 text-sm text-center'>{subSubCategory?.categoryName}</div>}
                        placement="left"
                        open={openSubSubCate}
                        onClose={handleCloseSubSubCateMenu}
                        width={250}
                        closeIcon={<div>&#10094;</div>}
                    >
                        {subSubCategory?.subcategories.map((subCate: any) => (
                            <div
                                key={subCate.id}
                                className="cursor-pointer flex justify-between items-center py-3 relative"
                            >
                                <a href={`/danh-muc/${subCate.categoryNameSlug}`} className='text-center uppercase font-semibold'>
                                    {subCate.categoryName}
                                </a>
                                {subCate.subcategories.length > 0 &&
                                    <div className='w-6 flex justify-center items-center'>
                                        &#10095;
                                    </div>
                                }
                            </div>
                        ))}
                    </Drawer>

                </div>
                <div className=' py-0 xs:px-0 px-4 md:flex hidden bg-white h-24 items-center'>

                    <div className="relative flex items-center justify-between mx-auto container">
                        <a href="/" className='lg:w-56 w-36 md:flex hidden sm:justify-center justify-start '>
                            <img src={logo} alt="Lê Hiếu Computer" className='' />
                        </a>

                        <div className='flex items-center'
                        >
                            <div
                                className="flex "

                            >
                                <div className="flex xxxl:space-x-16 xxl2:space-x-14 xxl:space-x-10 lg:space-x-6 space-x-1">
                                    {listCate.map((category: any, index: number) => {
                                        return (
                                            <a
                                                key={category.id}
                                                className="flex-shrink-0 cursor-pointer hover:bg-opacity-75 md:flex hidden items-center group py-2"
                                                href={`/danh-muc/${category.categoryNameSlug}`}
                                            >
                                                {category.categoryName === "Laptop"
                                                    ?
                                                    <div className=' w-5 h-5  relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-1.5'>
                                                        <LaptopIconComponent style='w-5 h-5 stroke-colorSecondary fill-none' />
                                                    </div>
                                                    :
                                                    category.categoryName === "PC"
                                                        ?
                                                        <div className=' w-6 h-6 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                            <PcIconComponent style='w-6 h-6 fill-colorSecondary ' />
                                                        </div>
                                                        :
                                                        category.categoryName === "Macbook"
                                                            ?
                                                            <div className=' w-6 h-6 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                <LaptopAppleIconComponent style='w-6 h-5 fill-colorSecondary' />
                                                            </div>
                                                            :
                                                            category.categoryName === "Máy tính bảng"
                                                                ?
                                                                <div className=' w-5 h-5 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                    <TabletPenIconComponent style='w-5 h-4 stroke-colorSecondary fill-none' />
                                                                </div>
                                                                :
                                                                category.categoryName === "Điện thoại"
                                                                    ?
                                                                    <div className=' w-5 h-5 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                        <SmartPhoneIconComponent style='w-6 h-4 stroke-2 fill-colorSecondary' />
                                                                    </div>
                                                                    :
                                                                    category.categoryName === "Linh kiện"
                                                                        ?
                                                                        <div className=' w-7 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                            <PcAcessIconComponent style='w-8 h-5 stroke-colorSecondary stroke-2 fill-none' />
                                                                        </div>
                                                                        :
                                                                        category.categoryName === "Phụ kiện điện tử"
                                                                            ?
                                                                            <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                <CPUIconComponent style='w-7 h-5 fill-colorSecondary' />
                                                                            </div>
                                                                            :
                                                                            category.categoryName === "Linh kiện PC"
                                                                                ?
                                                                                <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                    <PcAcessIconComponent style='w-8 h-5 stroke-colorSecondary stroke-2 fill-none' />
                                                                                </div>
                                                                                :
                                                                                category.categoryName === "Sim + Wifi"
                                                                                    ?
                                                                                    <div className=' w-4 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                        <SimIconComponent style='w-6 h-4 fill-colorSecondary' />
                                                                                    </div>
                                                                                    :
                                                                                    category.categoryName === "Sửa chữa"
                                                                                        ?
                                                                                        <div className=' w-5 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                            <RepairIconComponent style='w-6 h-4 fill-colorSecondary' />
                                                                                        </div>
                                                                                        : ''
                                                }

                                                <div className='text-colorSecondary uppercase xl:text-[15px] lg2:text-xs text-2xs'>
                                                    {category.categoryName}
                                                </div>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>


            <div className={` ${isFixed ? ' fixed animate-slideDown top-0 left-0 right-0 z-50 duration-300 bg-white shadow-xl' : 'hidden'}`}>
                {/* <div className={` container md:flex hidden items-center flex-wrap justify-between py-2 xs:px-0 px-4 mx-auto`}>
                    <div className='flex items-center xl:w-2/3 lg:w-1/2 sm:w-1/3 w-full justify-between sm:order-1 order-2'>
                        
                        <Popover placement="bottomLeft" title={''} content={content} arrow={false}
                        // trigger="click"
                        >
                            <div className="xl:hidden inline-flex cursor-pointer uppercase justify-center items-center rounded-md shadow-sm md:px-4 px-2 py-2.5 w-100 bg-white mr-2">
                                <div className='md:text-sm text-2xs font-semibold text-colorSecondary'>
                                    Danh mục sản phẩm
                                </div>

                                <ArrowDownIconComponent style='-mr-1 md:ml-2 ml-0 md:h-5 md:w-5 w-3.5 h-3.5 fill-colorSecondary' />
                            </div>
                        </Popover>
                        <div className='xl:block hidden rounded-lg w-full'>
                            <Space.Compact className='w-full'>
                                <Search
                                    placeholder="Tìm kiếm sản phẩm..."
                                    allowClear size='large'
                                    value={query}
                                    onChange={handleSearchChange}
                                    onSearch={handleSearch}
                                />
                            </Space.Compact>
                        </div>
                        <div className='xl:hidden lg:block hidden rounded-lg w-full'>
                            <Space.Compact className='w-full'>
                                <Search
                                    placeholder="Tìm kiếm sản phẩm..."
                                    allowClear size='large'
                                    value={query}
                                    onChange={handleSearchChange}
                                    onSearch={handleSearch}
                                />
                            </Space.Compact>
                        </div>
                        <div className='sm:hidden block rounded-lg w-full'>
                            <Space.Compact className='w-full'>
                                <Search
                                    placeholder="Tìm kiếm sản phẩm..."
                                    allowClear size='middle'
                                    value={query}
                                    onChange={handleSearchChange}
                                    onSearch={handleSearch}
                                />
                            </Space.Compact>
                        </div>
                    </div>

                    <div className='sm:pl-8 pl-4 flex-1 lg2:w-1/3 lg:w-1/2 sm:w-2/3 w-full sm:order-2 order-1 sm:mb-0 mb-2'>
                        <div className=' lg:py-2.5 py-1 bg-colorSecondaryHigher rounded-full flex justify-between'>
                            <div className=' -ml-4 flex items-center relative'>
                                <div className='xs:mr-2 mr-0.5 lg:w-11 lg:h-11 w-8 h-8 bg-white rounded-full flex items-center justify-center'>
                                    <PhoneIconComponent style='stroke-colorSecondary lg:w-6 lg:h-6 w-4 h-4' />

                                </div>
                                <div className='text-white'>
                                    <div className='font-medium block lg:text-sm xs:text-xs text-2xs'>Hotline</div>
                                    <div className='font-semibold lg:text-sm xs:text-xs text-2xs'>010-6222-1678</div>
                                </div>
                            </div>
                            <a href='/dang-ky' className=' flex items-center relative pr-4 cursor-pointer'>
                                <div className='mr-2 lg:w-11 lg:h-11 w-8 h-8 rounded-full flex items-center justify-center bg-white'>
                                    <UserPenIconComponent style='lg:w-6 lg:h-6 w-4 h-4 fill-colorSecondary' />

                                </div>
                                <div className='text-white'>

                                    <div className='font-medium lg:text-sm xs:text-xs text-2xs'>Thành viên</div>
                                </div>
                            </a>
                            <a href='/order' className=' flex items-center relative xs:pr-4 pr-2 cursor-pointer'>
                                <div className='lg:w-5 lg:h-5 w-3.5 h-3.5 absolute top-0 lg:left-7 left-5 lg:text-xs text-2xs flex items-center justify-center bg-colorThird text-white rounded-full font-semibold'>{totalQuantity > 9 ? '9+' : totalQuantity}</div>
                                <div className='xs:mr-2 mr-0.5 lg:w-11 lg:h-11 w-8 h-8 rounded-full flex items-center justify-center bg-white'>
                                    <CartIconComponent style='lg:w-6 lg:h-6 w-4 h-4 stroke-colorSecondary' />

                                </div>
                                <div className='text-white'>

                                    <div className='font-medium lg:text-sm xs:text-xs text-2xs'>Giỏ hàng</div>
                                </div>
                            </a>

                        </div>
                    </div>
                </div> */}
                <div className='container md:hidden flex items-center justify-between xs:px-0 px-4 mx-auto'>
                    <div onClick={showLoading}>
                        <TabSidebarIconComponent style='w-6 h-6 fill-colorSecondary' />
                    </div>

                    <div className='my-3 mx-3 w-full'>
                        <Space.Compact className='w-full'>
                            <Search
                                placeholder="Tìm kiếm sản phẩm..."
                                allowClear size='middle'
                                value={query}
                                onChange={handleSearchChange}
                                onSearch={handleSearch}
                            />
                        </Space.Compact>
                    </div>
                    <a href='/order' className=' relative cursor-pointer xs:pr-4 pr-2'>
                        <div className='py-0.5 px-1.5 absolute -top-1 left-3 text-2xs flex items-center justify-center bg-colorThird text-white rounded-full font-semibold'>{totalQuantity > 9 ? '9+' : totalQuantity}</div>
                        <CartIconComponent style='w-6 h-6 stroke-colorSecondary' />

                    </a>
                </div>
                <div className=' py-0 xs:px-0 px-4 md:flex hidden bg-white h-24 items-center'>

                    <div className="relative flex items-center xl:justify-between justify-start mx-auto container xl:space-x-2 lg:space-x-2.5 space-x-1.5">
                        <a href="/" className='lg:w-56 w-36 md:flex hidden sm:justify-center justify-start'>
                            <img src={logo} alt="Lê Hiếu Computer" className='' />
                        </a>

                        <div className='flex items-center'
                        >
                            <div
                                className="flex "

                            >
                                <div className="flex xxxl:space-x-16 xxl2:space-x-14 xxl:space-x-10 lg:space-x-6 space-x-1">
                                    {listCate.map((category: any, index: number) => {
                                        return (
                                            <a
                                                key={category.id}
                                                className="flex-shrink-0 cursor-pointer hover:bg-opacity-75 md:flex hidden items-center group py-2"
                                                href={`/danh-muc/${category.categoryNameSlug}`}
                                            >
                                                {category.categoryName === "Laptop"
                                                    ?
                                                    <div className=' w-5 h-5  relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-1.5'>
                                                        <LaptopIconComponent style='w-5 h-5 stroke-colorSecondary fill-none' />
                                                    </div>
                                                    :
                                                    category.categoryName === "PC"
                                                        ?
                                                        <div className=' w-6 h-6 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                            <PcIconComponent style='w-6 h-6 fill-colorSecondary ' />
                                                        </div>
                                                        :
                                                        category.categoryName === "Macbook"
                                                            ?
                                                            <div className=' w-6 h-6 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                <LaptopAppleIconComponent style='w-6 h-5 fill-colorSecondary' />
                                                            </div>
                                                            :
                                                            category.categoryName === "Máy tính bảng"
                                                                ?
                                                                <div className=' w-5 h-5 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                    <TabletPenIconComponent style='w-5 h-4 stroke-colorSecondary fill-none' />
                                                                </div>
                                                                :
                                                                category.categoryName === "Điện thoại"
                                                                    ?
                                                                    <div className=' w-5 h-5 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                        <SmartPhoneIconComponent style='w-6 h-4 stroke-2 fill-colorSecondary' />
                                                                    </div>
                                                                    :
                                                                    category.categoryName === "Linh kiện máy tính"
                                                                        ?
                                                                        <div className=' w-7 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                            <PcAcessIconComponent style='w-8 h-5 stroke-colorSecondary stroke-2 fill-none' />
                                                                        </div>
                                                                        :
                                                                        category.categoryName === "Phụ kiện điện tử"
                                                                            ?
                                                                            <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                <CPUIconComponent style='w-7 h-5 fill-colorSecondary' />
                                                                            </div>
                                                                            :
                                                                            category.categoryName === "Linh kiện PC"
                                                                                ?
                                                                                <div className=' w-8 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                    <PcAcessIconComponent style='w-8 h-5 stroke-colorSecondary stroke-2 fill-none' />
                                                                                </div>
                                                                                :
                                                                                category.categoryName === "Sim + Wifi"
                                                                                    ?
                                                                                    <div className=' w-4 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                        <SimIconComponent style='w-6 h-4 fill-colorSecondary' />
                                                                                    </div>
                                                                                    :
                                                                                    category.categoryName === "Sửa chữa"
                                                                                        ?
                                                                                        <div className=' w-5 h-8 relative transition-all bottom-0 flex items-center justify-center group-hover:-translate-y-2'>
                                                                                            <RepairIconComponent style='w-6 h-4 fill-colorSecondary' />
                                                                                        </div>
                                                                                        : ''
                                                }

                                                <div className='text-colorSecondary uppercase xl:text-[15px] lg2:text-xs text-2xs'>
                                                    {category.categoryName}
                                                </div>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </div>
    )
}

export default HeaderFormComponent
