/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import HomeService from '../../services/home';


const SliderComponent: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [images, setImages] = useState([]);

    const fetchImages = async () => {
        const result = await HomeService.getAllBannerByHome();

        if (result.data.message === 'success') {
            const data = result.data.data;

            const imageArray = data
                .filter((item: any) => item.homeType === "BANNER_TOP")
                .map((item: any) => item.image);

            setImages(imageArray);

            // console.log(result.data.data, 'data');
        }
    };

    const nextSlide = () => {
        if (images.length > 0) {
            const newIndex = (currentIndex + 1) % images.length;
            setCurrentIndex(newIndex);
        }
    };

    const prevSlide = () => {
        if (images.length > 0) {
            const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
            setCurrentIndex(newIndex);
        }
    };


    useEffect(() => {
        timeoutRef.current = setTimeout(nextSlide, 8000);
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [currentIndex]);

    useEffect(() => {
        fetchImages();
    }, []);

    return (
        <div className="relative mx-auto overflow-hidden rounded-lg w-full max-w-full">
            <div
                className="flex transition-transform duration-5000"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image: any, index) => (
                    <div key={index} className="w-full flex-shrink-0 ">
                        <img
                            src={`https://api.lehieucomputer.com${image}`}
                            alt={`Slide ${index + 1}`}
                            className="w-full aspect-[2.33333/1] h-auto bg-center object-cover"
                        />
                    </div>
                ))}
            </div>

            <button
                onClick={prevSlide}
                className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full"
            >
                &#10094;
            </button>
            <button
                onClick={nextSlide}
                className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full"
            >
                &#10095;
            </button>
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {images.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-colorSecondary' : 'bg-gray'}`}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default SliderComponent;
