import React from "react";
interface IConProps {
  style?: string;
}

const CommentIconComponent: React.FC<IConProps> = ({ style }) => {
  return (
    <svg className={style} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3251 2.33341C17.3251 1.41675 16.5834 0.666748 15.6667 0.666748L2.33341 0.666748C1.41675 0.666748 0.666748 1.41675 0.666748 2.33341L0.666748 12.3334C0.666748 13.2501 1.41675 14.0001 2.33341 14.0001L14.0001 14.0001L17.3334 17.3334L17.3251 2.33341ZM14.0001 10.6667L4.00008 10.6667L4.00008 9.00008L14.0001 9.00008V10.6667ZM14.0001 8.16675L4.00008 8.16675V6.50008L14.0001 6.50008V8.16675ZM14.0001 5.66675L4.00008 5.66675L4.00008 4.00008L14.0001 4.00008L14.0001 5.66675Z" className={style} />
    </svg>

  );
};

export default CommentIconComponent;
