/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";

const AdminGetAll = async () => {
    try {
        const response = await axiosInstance.get(`/api/AdminIntroduction/get_all`);
        if (response.data) {
            return {
                data: response.data,
            };
        } else {
            return { message: "Have no data" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const createIntroductionCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminIntroduction/create`, obj);
        if (response) {
            return {
                response,
                message: "Create Introduction successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};

const updateIntroductionCMS = async (obj: any) => {
    try {
        const response = await axiosInstance.post(`/api/AdminIntroduction/update`, obj);
        if (response) {
            return {
                response,
                message: "Update Introduction successfully!",
            };
        } else {
            return { message: "Something wrong! Try again" };
        }
    } catch (error: any) {
        throw {
            error: error.response ? error.response.data : error.message,
            message: "Internal server error.",
        };
    }
};



const IntroductionService = {
    AdminGetAll,
    createIntroductionCMS,
    updateIntroductionCMS
};

export default IntroductionService;
