import axiosInstance from "./axios";

const getList = async () => {
  try {
    const response = await axiosInstance.get("/todo/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const TodoService = {
  getList,
};

export default TodoService;
