import React, { useState, useEffect, useRef } from "react";
import { Button, Form, FormProps, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";

type FieldType = {
    username?: string;
    password?: string;
    remember?: boolean;
    fullname?: string;
    phone?: number;
    confirmPassword?: string;
};

const LoginRegister = () => {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true);
    const [loginForm] = Form.useForm();
    const [registerForm] = Form.useForm();
    const formWrapperRef = useRef<HTMLDivElement>(null); // Reference for form wrapper

    const toggleForm = () => {
        setIsLogin(!isLogin);
    };

    const adjustHeight = () => {
        // Adjust the height of the container dynamically based on form content
        if (formWrapperRef.current) {
            formWrapperRef.current.style.height = 'auto'; // reset height to auto first
            const newHeight = formWrapperRef.current.scrollHeight + 'px'; // get the new height
            formWrapperRef.current.style.height = newHeight; // set the new height
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [isLogin]);

    const onFinishLogin: FormProps<FieldType>["onFinish"] = async (values: any) => {
        const { username, password } = values;

        try {
            const result = await AuthService.Login(values);
            if (result.response) {
                localStorage.setItem("token", result.response.data.data);
                navigate("/");

                notification.success({
                    message: "Đăng nhập thành công",
                    duration: 2,
                });

                loginForm.resetFields();
            } else {
                notification.error({
                    message: "Đăng nhập thất bại",
                    description: "Tài khoản hoặc mật khẩu của bạn không chính xác!",
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Tài khoản chưa được xác thực",
                duration: 2,
            });
        }
    };

    const onFinishRegister: FormProps<FieldType>["onFinish"] = async (values: any) => {
        const { fullname, username, password, confirmPassword } = values;

        if (password !== confirmPassword) {
            notification.error({
                message: "Mật khẩu không khớp",
                duration: 2,
            });
            return;
        }

        try {
            const obj = {
                username: values.username,
                password: values.password,
            };
            const result = await AuthService.Register(obj);
            if (result.response) {
                notification.success({
                    message: "Đăng ký thành công",
                    duration: 2,
                });

                registerForm.resetFields();
                toggleForm();
            } else {
                notification.error({
                    message: "Đăng ký thất bại",
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: "Đăng ký thất bại",
                duration: 2,
            });
        }
    };

    return (
        <div className="bg-gradient-to-b from-colorLinearF to-colorLinearT h-screen flex justify-center items-center">
            <div
                ref={formWrapperRef} // Attach ref to form wrapper
                className="relative w-full max-w-lg overflow-hidden transition-all duration-700"
                style={{ height: 'auto' }} // Initial height is auto
            >
                <div
                    className={`transition-transform duration-700 flex flex-col justify-center bg-white rounded-lg p-8 relative ${isLogin ? "translate-x-0" : "-translate-x-full"
                        }`}
                >
                    <div className="text-center text-2xl font-bold mb-6">ĐĂNG NHẬP</div>
                    <Form
                        form={loginForm}
                        name="normal_login"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinishLogin}
                    >
                        <Form.Item<FieldType>
                            label="Tài khoản"
                            name="username"
                            rules={[{ required: true, message: "Vui lòng nhập tài khoản!" }]}
                        >
                            <Input size="large" placeholder="Tài khoản của bạn" />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Mật khẩu"
                            name="password"
                            rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
                        >
                            <Input.Password size="large" placeholder="Mật khẩu" />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" size="large" className="w-full bg-colorSecondary text-white">
                                ĐĂNG NHẬP
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="text-center">
                        <Button type="link" onClick={toggleForm}>
                            Chưa có tài khoản? Đăng ký
                        </Button>
                    </div>
                </div>

                <div
                    className={`absolute top-0 left-0 w-full p-8 transition-transform bg-white rounded-lg duration-700 ${isLogin ? "translate-x-full" : "translate-x-0"
                        }`}
                >
                    <div className="text-center text-2xl font-bold mb-6">ĐĂNG KÝ</div>
                    <Form
                        form={registerForm}
                        name="register"
                        layout="vertical"
                        onFinish={onFinishRegister}
                    >
                        <Form.Item<FieldType>
                            label="Tên đầy đủ"
                            name="fullname"
                            rules={[{ required: true, message: "Vui lòng nhập tên đầy đủ!" }]}
                        >
                            <Input size="large" placeholder="Tên đầy đủ" />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Số điện thoại"
                            name="phone"
                            rules={[
                                { required: true, message: "Vui lòng nhập số điện thoại!" },
                                { pattern: /^\d{10}$/, message: "Số điện thoại phải là 10 chữ số!" }
                            ]}
                        >
                            <Input size="large" placeholder="Số điện thoại" />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Email"
                            name="username"
                            rules={[{ required: true, message: "Vui lòng nhập Email!" }]}
                        >
                            <Input size="large" placeholder="Email" />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Mật khẩu"
                            name="password"
                            rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
                        >
                            <Input.Password size="large" placeholder="Mật khẩu" />
                        </Form.Item>
                        <Form.Item<FieldType>
                            label="Nhập lại mật khẩu"
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[{ required: true, message: "Vui lòng nhập lại mật khẩu!" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("Mật khẩu nhập lại không khớp!")
                                    );
                                },
                            }),
                            ]}
                        >
                            <Input.Password size="large" placeholder="Nhập lại mật khẩu" />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" size="large" className="w-full bg-colorSecondary text-white">
                                ĐĂNG KÝ
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="text-center">
                        <Button type="link" onClick={toggleForm}>
                            Đã có tài khoản? Đăng nhập
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginRegister;
