/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import NewsService from '../../../../services/news';
import UserCMSService from '../../../../services/user-cms';

const { Option } = Select;

const ManageUserForm = () => {
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = (record: any) => {
        // console.log(record.id, 'id')
        setIsModalOpen(true);
        setEditingItem(record.id);
        form.setFieldsValue(record);
    };

    const handleDelete = async (key: number) => {
        const obj = {
            id: key
        }
        const result = await UserCMSService.deleteAppUserCMS(obj);
        // console.log(result.response?.data, 'result form')
        if (result.response?.data.message === "success") {
            api.success({
                message: 'Xóa thành công người dùng!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
        fetchUserCMS();
    };

    const handleOk = () => {
        form.validateFields().then(async values => {
            if (editingItem) {
                // console.log(editingItem, 'editingItem', values)
                const obj = {
                    id: editingItem,
                    // fullName: values.fullName,
                    username: values.username,
                    password: values.password,
                    role: values.role,
                    status: values.status

                }
                const result = await UserCMSService.updateAppUserCMS(obj);
                // console.log(result.response?.data, 'result form')
                if (result.response?.data.message === "success") {
                    api.success({
                        message: 'Cập nhật thành công người dùng!',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                }
            } else {
                const result = await UserCMSService.createAppUserCMS(values);
                // console.log(result.response?.data, 'result form')
                if (result.response?.data.message === "success") {
                    api.success({
                        message: 'Tạo thành công người dùng mới!',
                        showProgress: true,
                        pauseOnHover: true,
                    });
                }

            }
            fetchUserCMS();
            setIsModalOpen(false);
        });
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchUserCMS = async () => {


        try {
            const result = await UserCMSService.getListAppUserCMS();
            // console.log(result.data, 'result')
            if (result.data.message === 'success') {
                setData(result.data.data);
            } else {
                notification.error({
                    message: 'Có lỗi xảy ra!',
                    description: result.data.message || 'Chi tiết lỗi không rõ.',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Lỗi hệ thống máy chủ!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchUserCMS();
    }, []);

    const columns = [

        // {
        //     title: 'Tên',
        //     dataIndex: 'fullName',
        //     key: 'fullName',
        // },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Mật khẩu',
            dataIndex: 'password',
            key: 'password',
        },
        {
            title: 'Vai trò',
            dataIndex: 'role',
            key: 'role',
            render: (role: string) => {
                return role === 'admin' ? 'Quản trị viên' : 'Nhân viên';
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                return status === 'Active' ? 'Đã xác nhận' : 'Chưa xác nhận';
            },
        },
        // {
        //     title: 'Mô tả',
        //     dataIndex: 'description',
        //     key: 'description',
        // },
        // {
        //     title: 'Đường dẫn',
        //     dataIndex: 'slug',
        //     key: 'slug',
        // },
        {
            title: '',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link"
                        onClick={() => handleEdit(record)}
                    >
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm người dùng
            </Button>
            <Table columns={columns} dataSource={data} rowKey="id" />

            <Modal
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                open={isModalOpen}
                width={1000}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                onCancel={handleCancel}>
                <Form form={form} layout="vertical">
                    {/* <Form.Item name="fullName" label="Tên" rules={[{ required: true, message: 'Hãy nhập tên!' }]}>
                        <Input />
                    </Form.Item> */}
                    <Form.Item name="username" label="Tên đăng nhập" rules={[{ required: true, message: 'Hãy nhập tên đăng nhập!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Mật khẩu"
                        name="password"
                        rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
                    >
                        <Input.Password
                            size="middle"
                            placeholder="Mật khẩu"
                        />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Vai trò"
                        rules={[{ required: true, message: 'Hãy chọn vai trò!' }]}
                    >
                        <Select placeholder="Chọn vai trò">
                            <Option value="admin">Quản trị viên</Option>
                            <Option value="staff">Nhân viên</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Trạng thái"
                        rules={[{ required: true, message: 'Hãy chọn trạng thái!' }]}
                    >
                        <Select placeholder="Chọn trạng thái">
                            <Option value="Active">Đã xác nhận</Option>
                            <Option value="Deactivate">Chưa xác nhận</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageUserForm;
