/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";


const deleteBank = async (id : any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminBankInformation/delete`, id);
      if (response) {
          return {
              response,
              message: "Delete category successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const updateBank = async (obj: any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminBankInformation/update`, obj);
      if (response) {
          return {
              response,
              message: "Update Category successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const createBank = async (object: any) => {
    try {
      const response = await axiosInstance.post(`/api/AdminBankInformation/create`, object);
      if (response) {
        return {
          response,
          message: "Add Bank successfully",
        };
      } else {
        return { message: "Can not add this Bank." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };

  const getAllBank = async () => {
    try {
      const response = await axiosInstance.get(`/api/AdminBankInformation/get_all`);
      if (response.data) {
        return {
          data: response.data,
        };
      } else {
        return { message: "Bank is null." };
      }
    } catch (error: any) {
      throw {
        error: error.response ? error.response.data : error.message,
        message: "Internal server error.",
      };
    }
  };


  const getAllBankClient = async () => {
    try {
      const response = await axiosInstance.get(`/api/Home/get_bank_informations_by_home`);
      if (response.data) {
        return {
          data: response.data,
        };
      } else {
        return { message: "Bank is null." };
      }
    } catch (error: any) {
      throw {
        error: error.response ? error.response.data : error.message,
        message: "Internal server error.",
      };
    }
  };

  const getBankById = async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/api/AdminBankInformation/get_by_id?Id=${id}`
      );
      if (response) {
        return {
          response,
        };
      } else {
        return { message: "Bank is does not exists." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };




const BankService = {
    createBank,
    updateBank,
    deleteBank,
    getAllBank,
    getBankById,
    getAllBankClient
};

export default BankService;
