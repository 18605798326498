/* eslint-disable no-throw-literal */
import { IClient, IfilterClient, IgetList, IupdateClient } from "../model";
import axiosInstance from "./axios";

const getClient = async (dataReq: IgetList) => {
  try {
    const response = await axiosInstance.get(
      `/api/client/get/${dataReq.pageIndex}/${dataReq.pageSize}`
    );
    if (response) {
      return {
        response,
        message: "get the list of client successfully",
      };
    } else {
      return { message: "Client  is Empty." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const exportClient = async () => {
  try {
    const response = await axiosInstance.get("/api/client/export");
    if (response) {
      return {
        response,
        message: "Dowload file excel",
      };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const filterClient = async (dataReq: IfilterClient) => {
  try {
    const response = await axiosInstance.get(
      `/api/client/get/${dataReq.name}/${dataReq.phone}`
    );
    if (response) {
      return {
        response,
        message: "Successful customer filtering",
      };
    } else {
      return { message: "User is not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const updateClient = async (dataReq: IClient) => {
  try {
    const response = await axiosInstance.put("/api/client/update", dataReq);
    if (response) {
      return {
        response,
        message: "Client updated successfully",
      };
    } else {
      return { message: "id not exist." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const deleteClient = async (id: number) => {
  try {
    const response = await axiosInstance.delete(`/api/client/delete/${id}`);
    if (response) {
      return {
        response,
        message: "Delete user successfully",
      };
    } else {
      return { message: "User is does not exists." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const snycUser = async (filePath: any) => {
  try {
    const formData = new FormData();
    formData.append("file", filePath);
    const response = await axiosInstance.post(
      "/api/client/snycUser",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response) {
      return {
        response,
        message: "SnycUser successfully",
      };
    } else {
      return { message: "SnycUser Error." };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const getListNewestClient = async (dataReq: IgetList) => {
  try {
    const response = await axiosInstance.get(
      `/api/client/getListNewestClient/${dataReq.pageIndex}/${dataReq.pageSize}`
    );
    if (response) {
      return {
        response,
        message: "Successful",
      };
    } else {
      return { message: "" };
    }
  } catch (error) {
    throw {
      error,
      message: "",
    };
  }
};

const GetListOldestClient = async (dataReq: IgetList) => {
  try {
    const response = await axiosInstance.get(
      `/api/client/getListOldestClient/${dataReq.pageIndex}/${dataReq.pageSize}`
    );
    if (response) {
      return {
        response,
        message: "Successful",
      };
    } else {
      return { message: "" };
    }
  } catch (error) {
    throw {
      error,
      message: "",
    };
  }
};

const getCLientIdByChannelId = async (dataReq: IgetList, channelId: number) => {
  try {
    const response = await axiosInstance.get(
      `/api/client/getCLientIdByChannelId/${dataReq.pageIndex}/${dataReq.pageSize}/${channelId}`
    );
    if (response) {
      return {
        response,
        message: "successfully",
      };
    } else {
      return { message: "failure" };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const getClientById = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/api/getClientById?id=${id}`);
    if (response) {
      return {
        response,
        message: "successfully",
      };
    } else {
      return { message: "failure" };
    }
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};

const UpdateClientbyId = async (id: number, dataReq: IupdateClient) => {
  try {
    const response = await axiosInstance.put(
      `/api/UpdateClientbyId/${id}`,
      dataReq
    );
    return response;
  } catch (error) {
    throw {
      error,
      message: "Internal server error.",
    };
  }
};
const ClientService = {
  getClient,
  exportClient,
  filterClient,
  updateClient,
  deleteClient,
  snycUser,
  getListNewestClient,
  GetListOldestClient,
  getCLientIdByChannelId,
  getClientById,
  UpdateClientbyId,
};

export default ClientService;
