/* eslint-disable react/style-prop-object */
import { Card, Popover } from 'antd';
import React from 'react';
import CartIconComponent from '../../assets/icons/CartIcon';
import DatabaseIconComponent from '../../assets/icons/DatabaseIcon';
import CheckIcon from '../../assets/icons/CheckIcon';
import GiftIconComponent from '../../assets/icons/GiftIcon';
import HomeService from '../../services/home';

interface ItemCardProps {
    id: number;
    title: string;
    slug: string;
    image: string;
    price: number;
    fixedPrice: number
    discount: number;
    guarantee: string;
    desc: string[];
    promotion: string[];
}

const ItemCardComponent: React.FC<ItemCardProps> = ({ id, title, image, price, fixedPrice, discount, guarantee, desc, promotion, slug }) => {


    const getDetailProduct = async (slug: any) => {
        const result = await HomeService.getProductBySlug(slug);
        // console.log(result.response?.data, 'result');

        if (result.response?.data.message === 'success') {

            const existingDataString = localStorage.getItem('orderInforTotalFormCard');
            let existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];

            if (!Array.isArray(existingDataArray)) {
                existingDataArray = [];
            }

            const newProductData = {
                id: result.response?.data.data.id,
                attributeGroups: result.response?.data.data.attributeGroups,
                namepc: result.response?.data.data.prodName,
                prodNameSlug: result.response?.data.data.prodNameSlug,
                logo: result.response?.data.data.logo,
                quantity: 1,
                totalPrice: result.response?.data.data.fixedPrice
            };

            const existingProduct = existingDataArray.find((item: any) => item.id === newProductData.id);

            if (existingProduct) {
                existingProduct.quantity += 1;
                existingProduct.totalPrice = result.response?.data.data.fixedPrice * existingProduct.quantity;
            } else {
                existingDataArray.push(newProductData);
            }

            localStorage.setItem('orderInforTotalFormCard', JSON.stringify(existingDataArray));

            const totalQuantity = existingDataArray.reduce((total: number, item: any) => {
                return total + item.quantity;
            }, 0);

            localStorage.setItem('totalQuantity', totalQuantity.toString());
            window.dispatchEvent(new Event('storage'));
        }
    };

    const totalPrice = price;

    return (
        <div className="py-2.5 px-2 rounded-lg bg-white text-sm item-product">
            <Card
                size="small"
                hoverable
                // style={{ width: 333, height: 340 }}
                className='xl:w-64 lg:w-72 md:w-87 w-full'
                cover={
                    <Popover
                        content={
                            <div className='max-w-100 min-w-100'>
                                <div className='bg-colorSecondary -mx-3 -mt-3 px-3 py-4 mb-3 text-white text-xl font-semibold !font-notoSanCJK'>{title}</div>
                                <div className='flex justify-between items-center'>
                                    <div className='font-bold text-sm !font-notoSanCJK'>Giá bán:</div>
                                    <div className='text-colorThird text-lg font-bold !font-notoSanCJK'>{totalPrice.toLocaleString('ko-KR', {
                                        style: 'currency',
                                        currency: 'KRW',
                                    })} </div>
                                </div>
                                {/* <div className='flex justify-between items-center'>
                                    <div className='font-bold text-sm !font-notoSanCJK'>Bảo hành:</div>
                                    <div className='text-sm !font-notoSanCJK'>{guarantee} Tháng</div>
                                </div> */}
                                {desc.length > 0 && (
                                    <>
                                        <div className='items-center bg-colorSecondary rounded-lg px-3 py-2 my-2 inline-flex'>
                                            <DatabaseIconComponent style='w-4 h-4 stroke-white mr-2' />
                                            <div className='text-sm font-bold text-white !font-notoSanCJK'>Mô tả tóm tắt:</div>
                                        </div>
                                        <div className='my-2'>
                                            {desc.map((descItem, index) => (
                                                <div key={index} className="flex items-start mb-1">
                                                    <div className='mr-2'>
                                                        <CheckIcon style="w-5 h-5 fill-colorSecondary" />
                                                    </div>
                                                    <div className="!font-notoSanCJK">{descItem}</div>

                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}

                                {promotion.length > 0 &&
                                    <>
                                        <div className="items-center bg-colorSecondary rounded-lg px-3 py-2 my-2 inline-flex">
                                            <GiftIconComponent style="w-4 h-4 fill-white mr-2" />
                                            <div className="text-sm font-bold text-white !font-notoSanCJK">Khuyến mãi:</div>

                                        </div>
                                        <div className="my-2">
                                            {promotion.map((promotionItem: any, index: number) => (
                                                <div key={index} className="flex items-start mb-1">
                                                    <div className="mr-2">
                                                        <CheckIcon style="w-5 h-5 fill-colorSecondary" />
                                                    </div>
                                                    <div className='text-black !font-notoSanCJK'>{promotionItem}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                }

                            </div>
                        } trigger="hover">
                        <a className="relative"
                            href={`/san-pham/${slug}.html`}
                        >
                            <img
                                alt={title}
                                src={image}
                                className="w-full rounded-t-lg object-cover h-56"
                            />
                            {discount !== 0 &&
                                <div className="absolute top-2 left-2 bg-gradient-to-r from-colorPrimary to-colorSecondary text-white px-2 py-1 text-sm font-semibold rounded">
                                    {discount}%
                                </div>
                            }

                        </a>
                    </Popover>

                }
            >
                <a className="hover:text-colorSecondary duration-400 overflow-hidden text-ellipsis line-clamp-2 h-11 !font-notoSanCJK"
                    href={`/san-pham/${slug}.html`}
                >
                    {title}
                </a>
                <div className="flex justify-between items-center my-2">
                    <div>
                        <div className="text-colorThird text-sm font-bold !font-notoSanCJK">{totalPrice.toLocaleString('ko-KR', {
                            style: 'currency',
                            currency: 'KRW',
                        })}</div>
                        {fixedPrice !== price &&
                            <div className="text-xs line-through !font-notoSanCJK">{(fixedPrice).toLocaleString('ko-KR', {
                                style: 'currency',
                                currency: 'KRW',
                            })}</div>
                        }

                    </div>
                    <div className="bg-colorSecondary rounded-full w-9 h-9 flex items-center justify-center hover:bg-colorThird duration-400"
                        onClick={() => getDetailProduct(slug)}
                    >
                        <CartIconComponent style="w-5 h-5 stroke-white" />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ItemCardComponent;
