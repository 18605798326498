import React from 'react'
interface IConProps {
    style?: string;
    stroke?: string;
}

const PlusIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 12H18M12 6V18" className={style} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default PlusIconComponent