import React from 'react'

interface IConProps {
    style?: string;
}

const SimIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512" xmlSpace="preserve">

            <g>
                <path className="st0" d="M417.926,15.89C408.142,6.092,394.51-0.007,379.566,0h-63.23l-1.85,9.086
		c-0.343,1.708-1.276,3.224-2.601,4.318c-1.335,1.094-2.938,1.721-4.813,1.728c-1.869-0.007-3.478-0.633-4.813-1.728
		c-1.322-1.094-2.255-2.611-2.601-4.318L297.815,0h-83.627l-1.849,9.086c-0.346,1.708-1.279,3.224-2.601,4.318
		c-1.335,1.094-2.941,1.721-4.81,1.728c-1.872-0.007-3.481-0.633-4.813-1.728c-1.322-1.094-2.258-2.611-2.601-4.318L195.664,0
		h-63.23c-14.944-0.007-28.579,6.092-38.356,15.89c-9.801,9.784-15.896,23.413-15.89,38.36v342.874
		c0,14.387,5.716,28.187,15.89,38.367l60.626,60.626C164.874,506.284,178.67,512,193.06,512h186.506
		c14.944,0.006,28.576-6.086,38.36-15.884c9.797-9.784,15.89-23.419,15.886-38.367V54.25
		C433.816,39.303,427.724,25.674,417.926,15.89z M411.116,457.75c-0.007,8.75-3.514,16.569-9.241,22.312
		c-5.743,5.723-13.559,9.238-22.308,9.244H193.06c-8.367,0-16.391-3.329-22.308-9.244l-60.623-60.626
		c-5.92-5.914-9.24-13.944-9.24-22.312V54.25c0.003-8.749,3.511-16.569,9.24-22.311c5.743-5.723,13.556-9.238,22.305-9.244h46.302
		c1.813,3.139,4.151,5.921,6.916,8.203c5.205,4.325,11.99,6.936,19.276,6.936c7.279,0,14.067-2.611,19.272-6.936
		c2.766-2.282,5.103-5.064,6.913-8.203h49.766c1.814,3.139,4.154,5.921,6.92,8.203c5.209,4.325,11.993,6.936,19.272,6.936
		c7.282,0,14.067-2.611,19.276-6.936c2.766-2.282,5.104-5.064,6.916-8.203h46.302c8.749,0.007,16.566,3.521,22.308,9.244
		c5.727,5.742,9.234,13.562,9.241,22.311V457.75z"/>
                <path className="st0" d="M323.668,81.988H188.332c-11.581,0-22.13,4.728-29.719,12.33c-7.599,7.589-12.33,18.138-12.326,29.723v135.335
		c-0.004,11.578,4.727,22.127,12.326,29.716c7.589,7.596,18.138,12.33,29.719,12.33h135.335c11.582,0,22.131-4.734,29.723-12.33
		c7.599-7.589,12.326-18.138,12.326-29.716V124.041c0-11.585-4.728-22.134-12.326-29.723
		C345.798,86.716,335.249,81.988,323.668,81.988z M158.593,124.041c0-8.209,3.323-15.62,8.72-21.02
		c5.407-5.4,12.808-8.723,21.02-8.723h63.564v30.251h-93.303V124.041z M158.593,132.757h72.949
		c-10.147,7.562-16.74,19.628-16.744,33.263v0.574h-56.205V132.757z M158.593,174.796h56.205v9.323l2.548,1.048
		c1.282,0.521,2.39,1.424,3.171,2.558c0.775,1.14,1.226,2.492,1.23,3.975c-0.003,1.497-0.455,2.842-1.23,3.976
		c-0.781,1.141-1.889,2.038-3.171,2.565l-2.548,1.042v9.35h-56.205V174.796z M251.896,289.112h-63.564
		c-8.212,0-15.613-3.33-21.02-8.716c-5.396-5.407-8.72-12.811-8.72-21.02v-0.501h93.303V289.112z M158.593,250.672v-33.837h60.069
		h0.234H223V204.46c1.681-1.094,3.148-2.486,4.282-4.14c1.678-2.446,2.667-5.433,2.664-8.618c0.003-3.178-0.986-6.164-2.664-8.61
		c-1.134-1.656-2.601-3.046-4.282-4.141v-12.93c0.004-9.211,3.725-17.518,9.758-23.558c5.789-5.783,13.664-9.409,22.417-9.706h2.298
		c8.752,0.296,16.625,3.923,22.417,9.706c6.036,6.039,9.758,14.347,9.758,23.558v12.785c-1.734,1.081-3.25,2.472-4.421,4.154
		c-1.737,2.466-2.766,5.506-2.762,8.742c-0.003,3.251,1.025,6.284,2.762,8.756c1.17,1.668,2.687,3.059,4.421,4.147v12.778
		c0,9.218-3.722,17.525-9.758,23.564c-5.881,5.875-13.905,9.527-22.813,9.725H158.593z M353.41,259.376
		c-0.004,8.208-3.327,15.613-8.726,21.02c-5.404,5.386-12.804,8.716-21.016,8.716h-63.563v-30.237h93.306V259.376z M353.41,250.672
		h-72.329c10.16-7.569,16.767-19.642,16.77-33.29v-0.547h55.559V250.672z M353.41,208.634h-55.559v-9.33l-2.634-1.009
		c-1.329-0.508-2.469-1.411-3.277-2.551c-0.804-1.154-1.269-2.519-1.272-4.042c0.003-1.51,0.468-2.881,1.272-4.028
		c0.808-1.154,1.948-2.05,3.277-2.558l2.634-1.01v-9.31h55.559V208.634z M353.41,166.594h-55.559v-0.574
		c-0.004-13.635-6.6-25.701-16.747-33.263h72.306V166.594z M353.41,124.549h-93.306V94.298h63.563
		c8.212,0,15.613,3.323,21.016,8.723c5.4,5.4,8.723,12.81,8.726,21.02V124.549z"/>
                <path className="st0" d="M191.461,358.144l-4.365-0.607c-9.326-1.312-13.48-4.338-13.48-9.83c0-5.986,4.507-9.712,11.766-9.712
		c6.64,0,12.85,2.044,19.523,6.402c0.112,0.066,0.204,0.099,0.287,0.099c0.168,0,0.323-0.152,0.392-0.231l7.398-11.044
		c0.247-0.35,0.244-0.474,0.244-0.474c0-0.007-0.033-0.092-0.333-0.297c-6.946-5.36-16.974-8.44-27.511-8.44
		c-17.264,0-28.421,9.594-28.421,24.429c0,13.575,8.542,21.626,25.378,23.947l4.358,0.606c9.498,1.285,13.372,4.174,13.372,9.949
		c0,6.521-5.443,10.563-14.198,10.563c-7.441,0-14.855-2.901-22.022-8.638c-0.148-0.119-0.3-0.184-0.428-0.184
		c-0.047,0-0.178,0-0.35,0.21l-9.191,10.523c-0.425,0.508,0.023,0.982,0.069,1.028c5.71,5.499,17.575,11.051,31.196,11.051
		c19.474,0,31.573-9.64,31.573-25.16C216.717,368.37,208.456,360.459,191.461,358.144z"/>
                <path className="st0" d="M237.928,325.342c-0.313,0-0.455,0.145-0.455,0.455v79.904c0,0.31,0.142,0.455,0.455,0.455H253.3
		c0.306,0,0.458-0.145,0.458-0.455v-79.904c0-0.304-0.152-0.455-0.458-0.455H237.928z"/>
                <path className="st0" d="M338.773,325.342c-0.676,0-1.002,0.198-1.24,0.758l-20.663,47.433h-1.47l-21.026-47.44
		c-0.201-0.474-0.475-0.751-1.246-0.751h-14.403c-0.31,0-0.455,0.145-0.455,0.455v79.904c0,0.31,0.145,0.455,0.455,0.455h13.678
		c0.307,0,0.455-0.145,0.455-0.455v-47.116h1.734l15.458,35.196c0.353,0.804,0.804,1.121,1.606,1.121h8.357
		c0.791,0,1.25-0.317,1.606-1.121l15.452-35.196h1.737v47.116c0,0.31,0.145,0.455,0.455,0.455h13.682
		c0.306,0,0.455-0.145,0.455-0.455v-79.904c0-0.304-0.149-0.455-0.455-0.455H338.773z"/>
            </g>
        </svg>
    )
}

export default SimIconComponent