import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TodoService } from "../services";

interface TodoItem {
  id: number | string;
  name: string;
  completed: boolean;
}

export interface TodoState {
  loading: boolean;
  list: TodoItem[];
}

const initialState: TodoState = {
  loading: false,
  list: [],
};

const todoSlice = createSlice({
  name: "todoSlice",
  initialState,
  reducers: {
    getListTodo: (state, action: PayloadAction<TodoItem[]>) => {
      if (state.loading) {
        state.loading = false;
        state.list = action.payload;
      }
    },
    addTodo: (state, action: PayloadAction<TodoItem>) => {
      state.list.push(action.payload);
    },
    completeTodo: (state, action: PayloadAction<TodoItem["id"]>) => {
      state.list.map((todo: TodoItem) => {
        if (todo.id === action.payload) {
          todo.completed = true;
        }
        return todo;
      });
    },
    removeTodo: (state, action: PayloadAction<TodoItem["id"]>) => {
      state.list.filter((todo: TodoItem) => todo.id === action.payload);
    },
    showTodoCongratulationAction: () => {
      alert("Chuc mung ban da tao thanh con todo 3 lan");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTodoList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTodoList.fulfilled, (state, action) => {
      state.list = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchTodoList.rejected, (state) => {
      state.list = [];
      state.loading = false;
    });
  },
});

export const fetchTodoList = createAsyncThunk("todoSlice/getList", async () => {
  const response = await TodoService.getList();
  return response.data;
});

export const {
  addTodo,
  completeTodo,
  removeTodo,
  getListTodo,
  showTodoCongratulationAction,
} = todoSlice.actions;
export default todoSlice.reducer;
