/* eslint-disable no-throw-literal */
import axiosInstance from "./axios";


const deleteBanner = async (id : any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminHomeItem/delete`, id);
      if (response) {
          return {
              response,
              message: "Delete category successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const updateBanner = async (obj: any) => {
  try {
      const response = await axiosInstance.post(`/api/AdminHomeItem/update`, obj);
      if (response) {
          return {
              response,
              message: "Update Category successfully!",
          };
      } else {
          return { message: "Something wrong! Try again" };
      }
  } catch (error: any) {
      throw {
          error: error.response ? error.response.data : error.message,
          message: "Internal server error.",
      };
  }
};

const createBanner = async (object: any) => {
    try {
      const response = await axiosInstance.post(`/api/AdminHomeItem/create`, object);
      if (response) {
        return {
          response,
          message: "Add Banner successfully",
        };
      } else {
        return { message: "Can not add this banner." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };

  const getAllBanner = async () => {
    try {
      const response = await axiosInstance.get(`/api/AdminHomeItem/get_all`);
      if (response.data) {
        return {
          data: response.data,
        };
      } else {
        return { message: "Banner is null." };
      }
    } catch (error: any) {
      throw {
        error: error.response ? error.response.data : error.message,
        message: "Internal server error.",
      };
    }
  };

  const getBannerById = async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/api/AdminHomeItem/get_by_id?Id=${id}`
      );
      if (response) {
        return {
          response,
        };
      } else {
        return { message: "Banner is does not exists." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };
  const getBannerByHomeType = async (type: string) => {
    try {
      const response = await axiosInstance.get(
        `/api/AdminHomeItem/get_all_by_home_type?homeType=${type}`
      );
      if (response) {
        return {
          response,
        };
      } else {
        return { message: "Banner is does not exists." };
      }
    } catch (error) {
      throw {
        error,
        message: "Internal server error.",
      };
    }
  };
  



const BannerService = {
    createBanner,
    updateBanner,
    deleteBanner,
    getAllBanner,
    getBannerById,
    getBannerByHomeType
};

export default BannerService;
