import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import BannerService from '../../../../services/banner';

const { Option } = Select;

const ManageTextPromotion = () => {
    const [dataTopText, setDataTopText] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = (record: any) => {
        setIsModalOpen(true);
        setEditingItem(record.id);
        form.setFieldsValue(record);
    };

    const handleDelete = async (key: number) => {
        const result = await BannerService.deleteBanner({ id: key });

        if (result.response?.data.message === "success") {
            api.success({
                message: `Xóa thành công sự kiện!`,
                showProgress: true,
                pauseOnHover: true,
            });
        }
        fetchDataByHomeType();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();

            const obj = {
                id: editingItem,
                text: values.text,
                homeType: values.homeType,
                status: values.status
            };
            const objCreate = {
                text: values.text,
                homeType: values.homeType,
                status: values.status
            };

            const result = editingItem
                ? await BannerService.updateBanner(obj)
                : await BannerService.createBanner(objCreate);

            if (result.response?.data.message === "success") {
                api.success({
                    message: editingItem ? `Cập nhật thành công sự kiện!` : `Tạo thành công sự kiện mới!`,
                    showProgress: true,
                    pauseOnHover: true,
                });
                fetchDataByHomeType();
            } else {
                api.error({
                    message: `Có lỗi xảy ra với PROMOTION!`,
                    description: result.response?.data.message || 'Unknown error',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }

            setIsModalOpen(false);
        } catch (error) {
            api.error({
                message: 'Vui lòng điền đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchDataByHomeType = async () => {
        try {
            const result = await BannerService.getBannerByHomeType("PROMOTION");
            if (result.response?.data.message === 'success') {
                setDataTopText(result.response.data.data);
                // console.log(result.response.data.data, 'res')
            } else {
                notification.error({
                    message: `Có lỗi xảy ra khi lấy dữ liệu PROMOTION!`,
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: `Lỗi hệ thống máy chủ khi lấy dữ liệu PROMOTION!`,
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchDataByHomeType();
    }, []);

    return (
        <div className='flex flex-col'>
            {contextHolder}
            {/* <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm văn bản Top Text
            </Button> */}
            <div className='font-semibold text-2xl'>Chỉnh sửa nhãn dán chương trình</div>
            <div className='my-6'>
                {dataTopText.map((item: any, index) => {
                    return (
                        <>
                            <div className='flex items-center my-2'>
                                <div className='mr-4'>Tên sự kiện:</div>
                                <div className='italic'>
                                    {item.text}
                                </div>
                            </div>
                            <div className='flex items-center my-2'>
                                <div className='mr-4'>Trạng thái:</div>
                                <div>{item.status === "Active" ? <div className='text-success italic'>Đã xác nhận</div> : <div className='text-colorThird italic'>Chưa xác nhận</div>}</div>
                            </div>

                            <div className='flex items-center my-2'>
                                <div className='mr-4'>Thực hiện chỉnh sửa sự kiện:</div>
                                <Button type="link" onClick={() => handleEdit(item)}>
                                    <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                                </Button>
                                {/* <Popconfirm
                                title="Bạn có muốn xóa mục này không?"
                                onConfirm={() => handleDelete(item.id)}
                            >
                                <Button type="link" danger>
                                    <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                                </Button>
                            </Popconfirm> */}
                            </div>
                        </>

                    )
                })}
            </div>


            <Modal
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                open={isModalOpen}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                width={1000}
                onCancel={handleCancel}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="text" label="Văn bản" rules={[{ required: true, message: 'Hãy nhập văn bản!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="homeType" label="Kiểu" initialValue="PROMOTION">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="status" label="Trạng thái" rules={[{ required: true, message: 'Hãy chọn trạng thái!' }]}>
                        <Select placeholder="Chọn trạng thái">
                            <Option value="Active">Đã xác nhận</Option>
                            <Option value="Deactivate">Chưa xác nhận</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageTextPromotion;
