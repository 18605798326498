import type { FormProps } from "antd";
import React from "react";
import {
    Button,
    Checkbox,
    Divider,
    Form,
    Input,
    message,
    notification,
} from "antd";
import UserDetailIconComponent from "../../assets/icons/UserDetailIcon";
import PasswordIconComponent from "../../assets/icons/PasswordIcon";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";

type FieldType = {
    username?: string;
    password?: string;
    remember?: boolean;
};

const LoginAdmin = () => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const onFinish: FormProps<FieldType>["onFinish"] = async (values: any) => {
        const { username, password } = values;

        // if (username === 'namdv' && password === 'string') {
        //     const token = Math.random().toString(36).substr(2);

        //     localStorage.setItem('token', token);

        //     navigate('/admin');

        //     notification.success({
        //         message: 'Đăng nhập thành công',
        //         duration: 2,
        //     });
        // } else {
        //     notification.error({
        //         message: 'Đăng nhập thất bại',
        //         description: 'Tài khoản hoặc mật khẩu của bạn không chính xác!',
        //         duration: 2,
        //     });
        // }
        try {
            const result = await AuthService.Login(values);
            // console.log(result.response?.data.data, 'rés')
            if (result.response && result.response) {
                localStorage.setItem('token', result.response.data.data);

                navigate('/admin');

                notification.success({
                    message: 'Đăng nhập thành công',
                    duration: 2,
                });
            } else {
                notification.error({
                    message: 'Đăng nhập thất bại',
                    description: 'Tài khoản hoặc mật khẩu của bạn không chính xác!',
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Đăng nhập thất bại',
                description: 'Đã xảy ra lỗi khi thực hiện yêu cầu đăng nhập.',
                duration: 2,
            });
        }
    };

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
        errorInfo
    ) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="bg-gradient-to-b from-colorLinearF to-colorLinearT h-screen flex justify-center items-center">
            <div className="w-full max-w-md bg-white p-8 shadow-xl rounded-lg">
                {contextHolder}
                <div className="text-center text-2xl font-bold mb-6">ĐĂNG NHẬP</div>
                <Form
                    name="normal_login"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item<FieldType>
                        label="Tài khoản"
                        name="username"
                        rules={[
                            { required: true, message: "Vui lòng nhập tên đăng nhập!" },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label="Mật khẩu"
                        name="password"
                        rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
                    >
                        <Input.Password
                            type="password"
                            size="large"
                            placeholder="Mật khẩu"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            size="large"
                            className="w-full bg-colorSecondary text-white"
                        >
                            ĐĂNG NHẬP
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>


    );
};

export default LoginAdmin;
