/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect, useRef } from "react";
import { Card, notification, Popover } from "antd";
import CartIconComponent from "../../assets/icons/CartIcon";
import DatabaseIconComponent from "../../assets/icons/DatabaseIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import GiftIconComponent from "../../assets/icons/GiftIcon";
import { useNavigate } from "react-router-dom";
import HomeService from "../../services/home";

const ItemListNewsComponent = ({ products }: any) => {
    const [startTouchX, setStartTouchX] = useState<number | null>(null);
    const [currentTouchX, setCurrentTouchX] = useState<number | null>(null);
    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setStartTouchX(event.touches[0].clientX);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        setCurrentTouchX(event.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (startTouchX !== null && currentTouchX !== null) {
            const diffX = startTouchX - currentTouchX;
            if (Math.abs(diffX) > 50) {
                if (diffX > 0) {
                    // Swipe left
                    nextSlide();
                } else {
                    // Swipe right
                    prevSlide();
                }
            }
        }
        setStartTouchX(null);
        setCurrentTouchX(null);
    };
    const getSlidesToShow = () => {
        const width = window.innerWidth;
        if (width >= 1600) return 5;
        if (width >= 1400) return 4;
        if (width >= 1200) return 3;
        if (width >= 992) return 2;
        if (width >= 576) return 1;
        return 1;
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = useRef(getSlidesToShow());
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const nextIndex = prevIndex + 1;
            if (nextIndex >= products.length) {
                return 0;
            }
            return nextIndex;
        });
    };
    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            const prevIndexWithOffset = prevIndex - 1;
            if (prevIndexWithOffset < 0) {
                return products.length - 1;
            }
            return prevIndexWithOffset;
        });
    };

    useEffect(() => {
        const handleResize = () => {
            itemsPerPage.current = getSlidesToShow();
            setCurrentIndex(itemsPerPage.current);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        startAutoSlide();
        return () => stopAutoSlide();
    }, [products.length]);

    const startAutoSlide = () => {
        if (!intervalRef.current) {
            intervalRef.current = setInterval(nextSlide, 5000);
        }
    };

    const stopAutoSlide = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };


    // const renderedProducts = [
    //     ...products.slice(-itemsPerPage.current, 0),
    //     ...products,
    //     ...products.slice(itemsPerPage.current),
    // ];
    const renderedProducts = products;
    

    return (
        <div className="">

            <div className="flex items-center relative">


                <div className="overflow-hidden w-full">
                    <div
                        className="flex transition-transform duration-2000 ease-linear"
                        style={{
                            transform: `translateX(-${currentIndex * (100 / itemsPerPage.current)
                                }%)`,
                        }}
                        onMouseEnter={stopAutoSlide}
                        onMouseLeave={startAutoSlide}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        {renderedProducts.map((product: any, index: number) => {

                            return (
                                <div
                                    key={index}
                                    className="px-2.5 rounded-lg text-sm flex-shrink-0"
                                    style={{
                                        width: `${100 / itemsPerPage.current}%`,
                                    }}
                                >
                                    <Card
                                        size="small"
                                        hoverable
                                        cover={
                                            <div className='mb-4 rounded-lg overflow-hidden group'>
                                                <a href={`/tin-tuc-chi-tiet/${product.slug}`} className='relative w-full overflow-hidden h-52'>
                                                    <img src={`https://api.lehieucomputer.com${product.logo}`} alt=""
                                                        className='rounded-t-lg block m-auto transition-transform duration-300 ease-in-out transform group-hover:scale-110 max-w-full w-full h-50.5 object-cover' />
                                                </a>
                                            </div>
                                        }
                                    >
                                        <a
                                            href={`/tin-tuc-chi-tiet/${product.slug}`}
                                            className="hover:text-colorThird xs:text-base text-sm duration-400 overflow-hidden text-ellipsis line-clamp-2 h-11 "
                                        >
                                            {product.title}

                                        </a>
                                    </Card>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* <button
                onClick={nextSlide}
                className="bg-colorSecondary bg-opacity-80 md:block hidden hover:bg-opacity-100 rounded-full text-white border-none md:px-4 px-2 md:py-2.5 py-0.5 cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
            >
                &#10095;
            </button> */}
            </div>
        </div>

    );
};

export default ItemListNewsComponent;
