import React from 'react'

interface IConProps {
    style?: string;
}

const TabletPenIconComponent: React.FC<IConProps> = ({ style }) => {
    return (
        <svg className={style} strokeMiterlimit='10' strokeWidth='1.91px' strokeLinecap='round' viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><rect className="cls-1" x="1.5" y="1.5" width="17.18" height="21" rx="1.91" /><line className="cls-1" x1="9.14" y1="19.64" x2="11.05" y2="19.64" /><path className="cls-2" d="M22,22.5l-.47-2.86a1,1,0,1,1,1.9,0L23,22.5a.49.49,0,0,1-1,0Z" /><line className="cls-3" x1="22.5" y1="1.5" x2="22.5" y2="19.64" /></svg>
    )
}

export default TabletPenIconComponent