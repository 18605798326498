/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, Switch, Upload, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import ProductService from '../../../../services/product';
import NewsService from '../../../../services/news';
import { env } from 'process';

interface Item {
    key: string;
    id:number;
    categoryName: string;
    categoryNameSlug: string;
    parentId: number;
    logo: File;
    status: string;
    subcategory:string;
    isShowHome: boolean;
    isShowMenu: boolean;
}

interface Subcategory {
    id: number;
    categoryName: string;
  }

const ManageItemsCategory
: React.FC<{ title: string }> = ({ title }) => {
    // const [data, setData] = useState<Item[]>([]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<Item | null>(null);
    const [form] = Form.useForm();

    const [listCate, setListCate] = useState<Item[]>([]);

    const [categoryById, setCategoryById] = useState<Item>();

    const getAllCategories = async () => {
        const result = await ProductService.getAllCategory();
    
        if (result.data.message === 'success') {
        //   setListCate(result.data.data);
        //   const formattedData = result.data.data.map((category : any, index : number) => ({
        //     key: category.id || index.toString(),
        //     categoryName: category.categoryName,
        //     categoryNameSlug: category.categoryNameSlug,
        //     logo: category.logo,
        //     status: category.status,
        //     createdTime: category.createdTime,
        //     subcategories: category.subcategories|| [],
        //     isShowHome: category.isShowHome,
        //     isShowMenu: category.isShowMenu,
        //     parentId:category.parentId
        //   }));
        const formattedData = await Promise.all(
            result.data.data.map(async (category: any, index: number) => {
                let subcategoryName : string = 'Không có danh mục phụ';

                if (category.parentId) {
                    await getCategoryById(category.parentId);
                    subcategoryName = categoryById?.categoryName ? categoryById?.categoryName : "";
                }
                return {
                    key: category.id || index.toString(),
                    categoryName: category.categoryName,
                    categoryNameSlug: category.categoryNameSlug,
                    logo: category.logo,
                    status: category.status,
                    createdTime: category.createdTime,
                    subcategory: subcategoryName,
                    isShowHome: category.isShowHome,
                    isShowMenu: category.isShowMenu,
                    parentId: category.parentId,
                };
            })
        );
          setListCate(formattedData);
        }
      };


      const getCategoryById = async (id: number) => {
        const result = await ProductService.getCategoryById(id);
        if (result.response?.data.message === 'success') {
          setCategoryById(result.response?.data.data);
        }
      };

      useEffect(() => {
        getAllCategories();
      }, []);

    //   console.log("listCate", listCate)
      const [api, contextHolder] = notification.useNotification();




    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = async (record: any) => {
        // console.log("record", record)
        const result = await ProductService.getCategoryById(record.key);
        setFileName(record.logo);
        setEditingItem(result.response?.data.data);

        const logoFileList = [
            {
                uid: '-1',
                name: record.logo.split('/').pop(),
                status: 'done',
                url: `https://api.lehieucomputer.com${record.logo}`,
            }
        ];
        // console.log("logoFileList", logoFileList)
        setIsModalOpen(true);
        form.setFieldsValue({...result.response?.data.data,logo: logoFileList});
    };

    const handleDelete = async (key: number) => {
        // console.log("record", record)
        // setListCate(listCate.filter(item => item.key !== key));
        const obj = {
            id: key
        }
        // console.log("obj", obj)
        const result = await ProductService.deleteCategory(obj);
        if (result.response?.data.message === "success") {
            // alert("Xóa thành công")
            api.success({
                message: 'Xóa danh mục thành công',
                showProgress: true,
                pauseOnHover: true,
            }); 
            window.location.reload();
        }
    };

    const [addCategory, setAddCategory] = useState()

    const { REACT_APP_API_URL } = process.env;
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            // form.validateFields().then(async values => {
                values.logo = `${REACT_APP_API_URL}${fileName}`;
                if (editingItem) {
                    Object.assign(values, {
                        ...values,
                        id:editingItem.id,
                        parentId:values.parentId,
                        categoryName: values.categoryName,
                        categoryNameSlug: values.categoryNameSlug,
                        logo: `${fileName}` || "",
                        status: values.status,
                    });
                    // console.log("logo", fileName)
                    const result = await ProductService.updateCategory(values);
    
                        if (result.response?.data.message === "success") {
                            api.success({
                                message: 'Update danh mục thành công',
                                showProgress: true,
                                pauseOnHover: true,
                              }); 
                              window.location.reload();
                        }
                } else {
                    const newValues = {
                        ...values,
                        categoryName : values.categoryName || "",
                        logo:`${fileName}` || "",
                        categoryNameSlug: values.categoryNameSlug || "",
                        status:values.status || "",
                        parentId:values.parentId || 0,
                    };
                
                                const result = await ProductService.createCategory(newValues);
                                if (result.response) {
                                    setAddCategory(result.response.data.data);
                                    api.success({
                                        message: 'Thêm danh mục thành công',
                                        showProgress: true,
                                        pauseOnHover: true,
                                    }); 
                                    window.location.reload();
                                }
    
                    setIsModalOpen(false);
                }
            
        } catch (error) {
            api.error({
                message: 'Vui lòng điền đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: 'Tên danh mục',
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        // {
        //     title: 'Category Name Slug',
        //     dataIndex: 'categoryNameSlug',
        //     key: 'categoryNameSlug',
        // },
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            render: (logo: any) => (
                <img
                    src={`https://api.lehieucomputer.com${logo}`}
                    alt="Logo"
                    style={{ width: '100px', height: 'auto' }}
                />
            ),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status : any) => (
                <span style={{ color: status === 'Active' ? 'green' : 'red', fontWeight: 'bold' }}>
                    {status === 'Active' ? 'Hoạt động' : 'Không hoạt động'}
                </span>
            ),
        },
        {
            title: 'Danh mục phụ',
            dataIndex: 'subcategory',
            key: 'subcategory',
        },
        // {
        //     title: 'Subcategories',
        //     dataIndex: 'subcategories',
        //     key: 'subcategories',
        //     render: (subcategories: Subcategory[]) => (
        //         <span>
        //           {subcategories.length > 0 
        //             ? (
        //                 <ul>
        //                   {subcategories?.map((sub,index) => (
        //                     <li key={`${sub.id}`}>{sub.parentId}</li> 
        //                   ))}
        //                 </ul>
        //               ) 
        //             : 'No Subcategories'}
        //         </span>
        //       ),
        // },
    
        {
            title: 'Hiện trên trang chủ',
            dataIndex: 'isShowHome',
            key: 'isShowHome',
            render: (isShowHome: boolean) => (isShowHome ? 'Có' : 'Không'),
        },
        {
            title: 'Hiện trên menu',
            dataIndex: 'isShowMenu',
            key: 'isShowMenu',
            render: (isShowMenu: boolean) => (isShowMenu ? 'Có' : 'Không'),
        },
        {
            title: 'Sửa/Xóa',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link" onClick={() => handleEdit(record)}>
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record?.key)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const { Option } = Select;

    const [availableSubcategories, setAvailableSubcategories] = useState([]);

    // const handleCustomRequest = async ({ file }: any) => {
    //     try {
    //       const response = await ProductService.uploadFile(file);
    //       console.log("response",response)
    //     } catch (error) {

    //     }
    //   };

    const [fileName,setFileName] = useState("")

    const handleUpload = async (options: any) => {
        const { file, onSuccess } = options;

        const formData = new FormData();
        formData.append('file', file);

        const result = await NewsService.uploadFileNewsCMS(formData);

        if (result.response) {
            onSuccess(result.response.data);
            setFileName(result.response.data.data);
        }
    };
     
    // console.log("fileName", fileName)

    return (
        <div>
            {contextHolder}
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Thêm danh mục sản phẩm
            </Button>
            <Table columns={columns} dataSource={listCate} />

            <Modal title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
                <Form form={form} layout="vertical">
                    <Form.Item name="categoryName" label="Danh mục" rules={[{ required: true, message: 'Hãy nhập danh mục!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="categoryNameSlug" label="Danh-mục" rules={[{ required: true, message: 'Hãy nhập danh-mục!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="logo"
                        label="Logo"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                        }}
                        rules={[{ required: true, message: 'Hãy nhập logo!' }]}
                    >
                        <Upload
                        name="logo"
                        listType="picture"
                        customRequest={handleUpload}
                        // beforeUpload={(file) => {
                        //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' ||file.type === 'image/jpg';
                        //     if (!isJpgOrPng) {
                        //     alert('You can only upload JPG/PNG file!');
                        //     }
                        //     const isLt2M = file.size / 1024 / 1024 < 2;
                        //     if (!isLt2M) {
                        //     alert('Image must smaller than 2MB!');
                        //     }
                        //     return isJpgOrPng && isLt2M;
                        // }}
                        maxCount={1}
                        >
                        <Button>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name="status" label="Trạng thái" rules={[{ required: true, message: 'Hãy nhập trạng thái!' }]}>
                                <Select placeholder="Chọn trạng thái">
                                    <Option value="Active">Active</Option>
                                    <Option value="Deactivate">Deactivate</Option>
                                </Select>
                    </Form.Item>
                    <Form.Item name="parentId" label="Danh mục phụ" rules={[{ required: false, message: 'Hãy chọn danh mục phụ!' }]}>
                        <Select placeholder="Chọn danh mục phụ">
                            {listCate.map((subcategory) => (
                                <Option key={`${subcategory.categoryNameSlug}-${subcategory.id}`} value={subcategory?.key}>
                                    {subcategory?.categoryName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="isShowHome" label="Hiện ở trang chủ" rules={[{ required: true, message: 'Hãy chọn có hoặc ko!' }]}>
                        <Select placeholder="Select Yes or No">
                            <Select.Option value={true}>Yes</Select.Option>
                            <Select.Option value={false}>No</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="isShowMenu" label="Hiện ở menu" rules={[{ required: true, message: 'Hãy nhập có hoặc ko!' }]}>
                        <Select placeholder="Select Yes or No">
                            <Select.Option value={true}>Yes</Select.Option>
                            <Select.Option value={false}>No</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageItemsCategory
;
