import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Select, notification } from 'antd';
import UserPenIconComponent from '../../../../assets/icons/UserPenIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import BannerService from '../../../../services/banner';

const { Option } = Select;

const ManageText = () => {
    const [dataTopText, setDataTopText] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const handleAdd = () => {
        setIsModalOpen(true);
        setEditingItem(null);
        form.resetFields();
    };

    const handleEdit = (record: any) => {
        setIsModalOpen(true);
        setEditingItem(record.id);
        form.setFieldsValue(record);
    };

    const handleDelete = async (key: number) => {
        const result = await BannerService.deleteBanner({ id: key });

        if (result.response?.data.message === "success") {
            api.success({
                message: `Xóa thành công văn bản TOP_TEXT!`,
                showProgress: true,
                pauseOnHover: true,
            });
        }
        fetchDataByHomeType();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();

            const obj = {
                id: editingItem,
                text: values.text,
                homeType: values.homeType,
                status: values.status
            };
            const objCreate = {
                text: values.text,
                homeType: values.homeType,
                status: values.status
            };

            const result = editingItem
                ? await BannerService.updateBanner(obj)
                : await BannerService.createBanner(objCreate);

            if (result.response?.data.message === "success") {
                api.success({
                    message: editingItem ? `Cập nhật thành công văn bản TOP_TEXT!` : `Tạo thành công văn bản mới TOP_TEXT!`,
                    showProgress: true,
                    pauseOnHover: true,
                });
                fetchDataByHomeType();
            } else {
                api.error({
                    message: `Có lỗi xảy ra với TOP_TEXT!`,
                    description: result.response?.data.message || 'Unknown error',
                    showProgress: true,
                    pauseOnHover: true,
                });
            }

            setIsModalOpen(false);
        } catch (error) {
            api.error({
                message: 'Vui lòng điền đầy đủ thông tin!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchDataByHomeType = async () => {
        try {
            const result = await BannerService.getBannerByHomeType("TOP_TEXT");
            if (result.response?.data.message === 'success') {
                setDataTopText(result.response.data.data);
            } else {
                notification.error({
                    message: `Có lỗi xảy ra khi lấy dữ liệu TOP_TEXT!`,
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            notification.error({
                message: `Lỗi hệ thống máy chủ khi lấy dữ liệu TOP_TEXT!`,
                showProgress: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
        fetchDataByHomeType();
    }, []);

    const columns = [
        {
            title: 'Văn bản',
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                if (status === 'Active') {
                    return <div className='text-success'>Đã xác nhận</div>;
                } else if (status === 'Deactivate') {
                    return <div className='text-colorThird'>Chưa xác nhận</div>;
                } else if (status === 'Deleted') {
                    return 'Đã xóa';
                } else {
                    return 'Trạng thái không xác định';
                }
            },
        },
        {
            title: '',
            key: 'actions',
            render: (_: any, record: any) => (
                <span>
                    <Button type="link" onClick={() => handleEdit(record)}>
                        <UserPenIconComponent style='w-5 h-5 fill-colorSecondary' />
                    </Button>
                    <Popconfirm
                        title="Bạn có muốn xóa mục này không?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <Button type="link" danger>
                            <DeleteIconComponent style='w-5 h-5 fill-colorThird' />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div style={{ display: 'flex', gap: '20px' }}>
            {contextHolder}

            {/* Table for Top Text */}
            <div style={{ flex: 1 }}>
                <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                    Thêm văn bản Top Text
                </Button>
                <Table columns={columns} dataSource={dataTopText} rowKey="id" />
            </div>

            <Modal
                title={editingItem ? 'Chỉnh sửa' : 'Thêm mới'}
                open={isModalOpen}
                onOk={handleOk}
                okText={editingItem ? 'Lưu chỉnh sửa' : 'Thêm mới ngay'}
                cancelText="Hủy"
                width={1000}
                onCancel={handleCancel}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="text" label="Văn bản" rules={[{ required: true, message: 'Hãy nhập văn bản!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="homeType" label="Kiểu" initialValue="TOP_TEXT">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="status" label="Trạng thái" rules={[{ required: true, message: 'Hãy chọn trạng thái!' }]}>
                        <Select placeholder="Chọn trạng thái">
                            <Option value="Active">Đã xác nhận</Option>
                            <Option value="Deactivate">Chưa xác nhận</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageText;
