/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  FormProps,
  Modal,
  notification,
  Popover,
  Table,
  TableColumnsType,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../assets/icons/CheckIcon";
import ACheckIcon from "../../assets/icons/ACheckIcon";
import ACheckIconblack from "../../assets/icons/ACheckIconblack";
import SettingIconComponent from "../../assets/icons/SettingIcon";
import GiftIcon from "../../assets/icons/GiftIcon";
import PlusIconComponent from "../../assets/icons/PlusIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { Checkbox, Form, Input } from "antd";
import HomeService from "../../services/home";
import MinusIcon from "../../assets/icons/MinusIcon";
import ProductService from "../../services/product";
import BankService from "../../services/bank";

interface Order {
  fullname: string;
  phone: string;
  email: string;
  address: string;
  note: string;
  products: Products[];
}
interface Products {
  id: number;
  quantity: number;
  attributeIds: [];
}

interface OrderItem {
  id: number;
  totalPrice: number;
  quantity: number;
  logo?: string;
  namepc?: string;
  attributeGroups?: [];
}

interface Bank {
  description: string;
  bankName: string,
  bankNumber: string,
  bankAccount: string,
  status: string,
}
function Order() {
  type FieldType = {
    fullname?: string;
    phone?: number;
    email?: string;
    address?: string;
    note?: string;
  };

  const [valuesCustomer, setValuesCustomer] = useState<Order>();
  const [isOrderSuccess, setIsOrderSuccess] = useState(false);
  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const [api, contextHolder] = notification.useNotification();



  const [isModalVisible, setIsModalVisible] = useState(false);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [productsBySlug, setProductsBySlug] = useState<any[]>([]);


  const orderInforTotalFormCard = JSON.parse(
    localStorage.getItem("orderInforTotalFormCard") || "[]"
  );
  const orderInforTotal = JSON.parse(
    localStorage.getItem("orderInforTotal") || "{}"
  );

  const [listBank, setListBank] = useState<Bank[]>([])

  const getAllBank = async () => {
    const result = await BankService.getAllBankClient();

    if (result.data.message === 'success') {
    const formattedData: any = await Promise.all(
        result.data.data.map(async (bank: any, index: number) => {
            return {
                key: bank.id || index.toString(),
                bankName: bank.bankName,
                bankNumber: bank.bankNumber,
                bankAccount: bank.bankAccount,
                description:bank.description,
                status: bank.status,
            };
        })
    );
      setListBank(formattedData);
    }
  };

  //   const getProductById = async (id: number) => {
  //     const result = await ProductService.getProductById(id);
  //     if (result.response?.data.message === 'success') {
  //         (result.response?.data.data);
  //     }
  // };

  // const [newArrayOrder, setNewArrayOrder] = useState([]);
  const initialNewArrayOrder: any = [...orderInforTotalFormCard, orderInforTotal];

  const [newArrayOrder, setNewArrayOrder] = useState<OrderItem[]>([...initialNewArrayOrder]);


  useEffect(() => {
    const fetchProducts = async () => {
      const newArrayOrderWithoutLast = newArrayOrder.slice(0, -1);
      const prodNameSlugs = newArrayOrderWithoutLast.map((item: any) => item.prodNameSlug);
      const productsPromises = prodNameSlugs.map(async prodNameSlug => {
        try {
          const result = await HomeService.getProductBySlug(prodNameSlug);
          if (result.response?.data.message === 'success') {
            return result.response?.data.data;
          }
          return null;
        } catch (error) {
          console.error(`Error fetching product by slug ${prodNameSlug}:`, error);
          return null;
        }
      });

      const productsData = await Promise.all(productsPromises);

      setProductsBySlug(productsData.filter(product => product !== null));
    };
    const updatedOrderInforTotalFormCard = newArrayOrder.filter(item => item.id !== orderInforTotal.id);
    localStorage.setItem('orderInforTotalFormCard', JSON.stringify(updatedOrderInforTotalFormCard));
    localStorage.setItem('orderInforTotal', JSON.stringify(orderInforTotal));

    fetchProducts();

  }, [newArrayOrder]);


  const [quantity, setQuantity] = useState(orderInforTotal.quantity);


  const price = newArrayOrder
    ?.filter((item) => item && item.totalPrice !== undefined && item.quantity !== undefined)
    .reduce((accumulator, item) => {
      return accumulator + (item.totalPrice * item.quantity);
    }, 0);

  const totalQuantity: any = newArrayOrder?.reduce((accumulator, item) => {
    return accumulator + (item.quantity || 0);
  }, 0);


  localStorage.setItem("totalQuantity", JSON.stringify(totalQuantity));
  // const totalQuantity = parseInt(localStorage.getItem("totalQuantity") || "0");


  const formatPrice = (price)
    ? (price).toLocaleString("ko-KR")
    : (price);

  // console.log("newArrayOrder", newArrayOrder)


  const test = newArrayOrder?.map((item: any, index: number) => {

    const objectOrder = item.attributeGroups?.map((group: any) =>
      group.attributes.filter((attr: any) => attr.isSelected)
    );

    // Get the attribute IDs
    const attributeIdsTest = objectOrder?.map((attributes: any[]) =>
      attributes?.map((attr: any) => attr.id)
    );

    const attributeIds = attributeIdsTest?.flat();


    const newProduct: Products = {
      id: item.id,
      quantity: item.quantity,
      attributeIds: attributeIds,
    };

    return newProduct;
  });

  const products: Products[] = test;

  const productsWithoutLast = products.slice(0, -1);
  console.log("valuesCustomer", valuesCustomer)

  const objectApi = {
    fullName: valuesCustomer?.fullname,
    phone: valuesCustomer?.phone,
    email: valuesCustomer?.email,
    address: valuesCustomer?.address,
    note: valuesCustomer?.note,
    products: products,
  };





  const onFinish: FormProps<FieldType>["onFinish"] = async (values: any) => {
    setValuesCustomer(values);
    const totalQuantity = parseInt(localStorage.getItem("totalQuantity") || "0");
    if (totalQuantity <= 0) {
      api.warning({
        message: 'Không thể đặt hàng vì không có gì trong giỏ hàng!',
        showProgress: true,
        pauseOnHover: true,
      });
      return;
    }

    const requestAPI = {
      fullName: values?.fullname,
      phone: values?.phone,
      email: values?.email,
      address: values?.address,
      note: values?.note,
      products: products,
    };

    console.log("products", requestAPI)
    try {
      const response: any = await HomeService.order(requestAPI);
      api.success({
        message: 'Đặt hàng thành công',
        showProgress: true,
        pauseOnHover: true,
      });
      setIsOrderSuccess(true);
      setShowFirstDiv(false);
      localStorage.removeItem("orderInforTotalFormCard");
      localStorage.removeItem("orderInforTotal");
    } catch (error) {
      console.error("Error:", error);
      setIsOrderSuccess(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };



  const handleIncrease = () => {
    setQuantity((prevQuantity: any) => {
      return prevQuantity + 1;
    });
  };
  const handleDecrease = () => {
    setQuantity((prevQuantity: any) => (prevQuantity > 0 ? prevQuantity - 1 : 0));
  };

  const handleQuantityChange = (index: any, newQuantity: any) => {
    const updatedArray = [...newArrayOrder];
    updatedArray[index].quantity = newQuantity;

    // localStorage.setItem("newArrayOrder", JSON.stringify(updatedArray));
    setNewArrayOrder(updatedArray);
  };

  const handleDelete = (indexToDelete: number) => {
    const updatedArray = newArrayOrder.filter((_, index) =>
      index !== indexToDelete
    );
    setNewArrayOrder(updatedArray);

    if (updatedArray.length === 0) {
      localStorage.removeItem('orderInforTotal');
    }
    window.location.reload();
  };

  const handleConfirmOrder = () => {
    window.location.reload();
    localStorage.removeItem('orderInforTotal');
    localStorage.removeItem('orderInforTotalFormCard');
  };


  useEffect(() => {
    handleIncrease();
    handleDecrease();
  }, [quantity, formatPrice]);


  useEffect(() => {
    getAllBank();
  }, []);

  return (
    <div>
      {contextHolder}
      {showFirstDiv && (
        <div className="mx-auto container xxs:w-300 xxxxl:w-full">
          <div className=" pt-5 pb-5 ">
            <div className="relative xs:mr-4 xs:ml-4 rounded-t-md bg bg-white p-4 mb-10 shadow-sm ">
              <div className="flex lg:justify-between lg:items-center md:justify-start lg:flex-row flex-row mb-5 font-semibold">
                <div className=" flex lg:w-5/6 md:w-5/6 w-5/6">
                  <div className="md:flex justify-center items-center w-24 xs:hidden hidden">
                    STT
                  </div>
                  <div className=" md:w-1/6 w-1/6 mr-2 flex items-center">
                    Hình ảnh
                  </div>
                  <div className="flex md:flex-row  w-1/2 md:justify-between justify-center items-center md:pr-4">
                    <div className="">
                      Sản phẩm
                    </div>
                    <div className="md:flex text-center font-semibold xs:hidden hidden">Giá tiền</div>
                  </div>
                  <div className="flex flex-row items-center md:ml-32 ">
                    Số lượng
                  </div>
                </div>
                <div className="md:flex justify-between items-center w-1/6 xs:hidden hidden">
                  Thành tiền
                </div>
              </div>
              {newArrayOrder?.length === 0 || (Object.keys(newArrayOrder[0]).length === 0) ? (
                <div className="text-center font-semibold text-gray-500 mb-5">
                  Không có gì trong giỏ hàng
                </div>
              ) : (
                newArrayOrder
                  ?.filter((item: any) => item && item.totalPrice !== undefined && item.namepc)?.map((item1: any, index: number) => (
                    <div className="flex lg:justify-between lg:items-center md:justify-start lg:flex-row flex-row mb-5">
                      <div className=" flex lg:w-5/6 md:w-5/6 w-5/6">
                        <div className="md:flex justify-center items-center w-24 xs:hidden hidden">
                          {index + 1}
                        </div>
                        <div className=" md:w-1/6 w-1/6 h-full  mr-2">
                          <img
                            className="md:w-32 md:h-20 w-16 h-10 inset-0 object-cover rounded-md"
                            src={item1.logo ? item1.logo.split("|")[0] : ""}
                            alt="No image"
                          />
                        </div>
                        <div className="flex xs:text-sm text-xs items-center md:flex-row flex-col w-1/2 justify-between">
                          {/* <div className="xs:text-sm text-xs items-center"> */}
                            <div className="font-semibold">{item1?.namepc}</div>
                            {item1?.attributeGroups?.map(
                              (item: any, index: number) => (
                                <div className="flex">
                                  <div className="font-semibold">
                                    {item?.attributeGroupName} :
                                  </div>
                                  {item?.attributes
                                    .filter((attribute: any) => attribute?.isSelected === true) // Filter attributes where isFalse is true
                                    ?.map((attribute: any, idx: number) => (
                                      <p className="text-colorThird ml-2" key={idx}>
                                        {attribute?.attributeName}
                                      </p>
                                    ))}
                                </div>
                              )
                            )}
                          {/* </div> */}
                          <div className="flex items-center text-center md:text-md text-sm md:font-normal font-semibold">₩{item1.totalPrice !== undefined ? item1.totalPrice.toLocaleString("ko-KR") : "0"}</div>
                        </div>
                        <div className="flex flex-row items-center md:ml-32 ">
                          <div className="font-semibold md:w-20 md:h-8 w-10 h-6 rounded-md md:mb-0 flex justify-center items-center border border-colorBorder mr-3">
                            {/* {item1?.quantity} */}
                            <input
                              type="number"
                              value={item1.quantity}
                              onChange={(e) => handleQuantityChange(index, Math.max(0, parseInt(e.target.value)))}
                              className="w-full flex justify-center items-center text-center"
                            />
                          </div>
                          {/* <div
                      className="w-8 h-8 rounded-md flex justify-center items-center border border-colorBorder mr-3"
                      onClick={handleIncrease}
                    >
                      <PlusIconComponent style="stroke-colorPrimary w-6 h-6 cursor-pointer" />
                    </div>
                    <div
                      className="w-8 h-8 rounded-md flex justify-center items-center border border-colorBorder mr-3"
                      onClick={handleDecrease}
                    >
                      <MinusIcon style="stroke-colorPrimary w-6 h-6 cursor-pointer" />
                    </div> */}
                        </div>
                      </div>
                      <div className="flex justify-between items-center w-1/6">
                        <div className="font-semibold md:block xs:hidden hidden text-sm md:text-md mr-2">
                          ₩{(item1.totalPrice * item1.quantity) !== undefined ? (item1.totalPrice * item1.quantity).toLocaleString("ko-KR") : "0"}
                        </div>
                        <div className=" flex justify-center items-center md:w-20 md:h-8 w-6 h-6" onClick={() => handleDelete(index)}>
                          <DeleteIcon style="md:w-6 md:h-6 w-5 h-5" />
                        </div>
                      </div>
                    </div>
                  )))
              }
              <div className="h-20 border-t border-t-colorBorder justify-end flex md:justify-start lg2:justify-end items-center">
                <div className="flex">
                  <div className="font-semibold text-sm flex items-center mr-2">
                    Tổng tiền :
                  </div>
                  <div className="text-colorThird font-semibold">
                    ₩{formatPrice}
                  </div>
                </div>
              </div>
            </div>

            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              className="w-full"
              // style={{ maxWidth: 800 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-col lg2:flex-row rounded-md xs:mr-4 xs:ml-4 bg bg-white p-4 ">
                <div className="flex flex-row xs:w-full w-full lg:w-full ">
                  <div className="flex flex-col lg:flex-row w-full">
                    <div className="w-full lg:w-full mr-5">
                      <div className="font-semibold  mb-3">THÔNG TIN NGƯỜI MUA</div>
                      <Form.Item<FieldType>
                        label="Họ và tên"
                        name="fullname"
                        rules={[
                          { required: true, message: "Please input your fullname!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item<FieldType>
                        label="SĐT"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item<FieldType>
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Please input your email!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item<FieldType>
                        label="Địa chỉ"
                        name="address"
                        rules={[
                          { required: true, message: "Please input your address!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item<FieldType>
                        label="Ghi chú"
                        name="note"
                        rules={[
                          { required: false, message: "Please input your email!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="w-full xs:w-full">
                      <div className="font-semibold mb-3">TỔNG TIỀN</div>
                      <div className="flex">
                        <Input placeholder="Mã Voucher" />
                        <div>
                          {/* <Popover content={chooseVoucher} title="Title" trigger="click"> */}
                          <Button
                            onClick={showModal}
                            className="h-12 bg bg-colorSecondary text-white ml-5"
                          >
                            Nhập mã voucher
                          </Button>
                          {/* </Popover> */}
                        </div>
                      </div>
                      <div className="mt-3 mb-3">
                        <div className="flex justify-between">
                          <p className="text-sm">Tổng cộng</p>
                          <p>₩{formatPrice}</p>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-sm">Giảm giá voucher</p>
                          <p>₩0</p>
                        </div>
                        <div className="flex justify-between mb-3">
                          <p className="font-semibold text-sm">Thành tiền</p>
                          <p className="font-semibold text-colorThird">
                            ₩{formatPrice}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <div className="xs:mr-4 xs:ml-4 p-4 pt-5 bg bg-white rounded-b-md">
                {/* <div className="font-semibold  mb-3">THANH TOÁN</div>
                <div className="mb-3">Toàn bộ giao dịch được bảo mật và mã hóa </div>
                <div className="border border-colorSecondary w-1/2 h-10 p-2 rounded-lg">
                  Tiền gửi ngân hàng
                </div>
                <div className="border border-colorBorder border-t-0 p-4 w-1/2">
                  Quý khách vui lòng chuyển khoản vào tài khoản sau để thanh toán:<br></br>

                  {listBank?.map((item: any)=>(
                    <div>
                      <div className="text-sm font-bold">
                        Ngân hàng: {item?.bankName}
                      </div>
                      <div className="text-sm font-bold">
                      {item?.bankNumber}
                      </div>
                      <div className="text-sm font-bold">
                        Chủ tài khoản : {item?.bankAccount}
                      </div>
                    </div>
                  ))}
                  ※Lưu ý: phí chuyển khoản sẽ do khách hàng chi trả.<br></br>
                  ※Chuyển đúng số tiền của đơn hàng để hệ thống xác nhận.<br></br>
                  ※Sau khi chuyển khoản, gọi trực tiếp vào số cửa hàng để xác nhận đơn hàng hoặc gửi bill vào fanpage được kí hiệu góc bên phải màn hình<br></br>
                </div> */}
                <div dangerouslySetInnerHTML={{ __html: listBank[0]?.description ?? "" }}/> 
              </div>
              <Form.Item className=" flex justify-center mt-5 xs:mr-4 xs:ml-4" wrapperCol={{ offset: 0 }}>
                <Button
                  className="h-10 w-48  bg bg-colorSecondary text-white"
                  htmlType="submit"
                >
                  Đặt hàng
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

      )}
      {isOrderSuccess && (
        <div className="mx-auto container xxs:w-300 xxxxl:w-full">
          <div className=" pt-5 pb-5">
            <div className="flex flex-col lg2:flex-col rounded-md xs:mr-4 xs:ml-4 bg-white p-4 ">
              <div className="mb-3 mt-3 text-sm italic">
                * Các đơn hàng từ 21h30 tối tới 8h sáng hôm sau. Lê Hiếu Computer sẽ liên hệ với Quý khách trước 10h trưa cùng ngày
              </div>
              <div className="flex flex-row xs:w-full w-full lg:w-full">
                <Form
                  name="basic"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 24 }}
                  className="w-full"
                  // style={{ maxWidth: 800 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <div className="flex flex-col lg:flex-row w-full">
                    <div className="w-full lg:w-full mr-5 bg bg-colorPrimary p-4">
                      <div className="font-semibold mb-3 text-xl text-center">THÔNG TIN ĐƠN ĐẶT HÀNG</div>
                      <div className="text-md">
                        <div className="flex">
                          <div>
                            Họ tên người đặt hàng:
                          </div>
                          <div className="font-semibold ml-3">
                            {objectApi.fullName}
                          </div>
                        </div>
                        <div className="flex">
                          <div>
                            Số điện thoại:
                          </div>
                          <div className="font-semibold ml-3">
                            {objectApi.phone}
                          </div>
                        </div>
                        <div className="flex">
                          <div>
                            Email:
                          </div>
                          <div className="font-semibold ml-3">
                            {objectApi.email}
                          </div>
                        </div>
                        <div className="flex">
                          <div>
                            Địa chỉ:
                          </div>
                          <div className="font-semibold ml-3">
                            {objectApi.address}
                          </div>
                        </div>
                        <div className="flex">
                          <div>
                            Ghi chú:
                          </div>
                          <div className="font-semibold ml-3">
                            {objectApi.note}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="relative xs:mr-4 xs:ml-4 rounded-md bg bg-white p-4 mb-10 shadow-sm ">
              {newArrayOrder?.length === 0 || (Object.keys(newArrayOrder[0]).length === 0) ? (
                <div className="text-center font-semibold text-gray-500 mb-5">
                  Không có gì trong giỏ hàng
                </div>
              ) : (
                newArrayOrder
                  ?.filter((item: any) => item && item.totalPrice !== undefined && item.namepc)?.map((item1: any, index: number) => (
                    <div className="flex lg:justify-between lg:items-center md:justify-start lg:flex-row flex-row">
                      <div className=" flex lg:w-5/6 md:w-5/6 w-5/6">
                        <div className=" md:w-1/6 w-1/6 h-full  mr-2">
                          <img
                            className="md:w-32 md:h-20 w-16 h-10 inset-0 object-cover rounded-md"
                            src={item1.logo ? item1.logo.split("|")[0] : ""}
                            alt="No image"
                          />
                        </div>
                        <div className="flex md:flex-row flex-col w-1/2 justify-between">
                          <div className="xs:text-sm text-xs pb-5">
                            <div className="font-semibold">{item1?.namepc}</div>
                            {item1?.attributeGroups?.map(
                              (item: any, index: number) => (
                                <div className="flex">
                                  <div className="font-semibold">
                                    {item?.attributeGroupName} :
                                  </div>
                                  {item?.attributes
                                    .filter((attribute: any) => attribute?.isSelected === true) // Filter attributes where isFalse is true
                                    ?.map((attribute: any, idx: number) => (
                                      <p className="text-colorThird ml-2" key={idx}>
                                        {attribute?.attributeName}
                                      </p>
                                    ))}
                                </div>
                              )
                            )}
                          </div>
                          <div className="flex items-center text-center md:text-md text-sm md:font-normal font-semibold">{item1.totalPrice !== undefined ? item1.totalPrice.toLocaleString("vi-VN") : "0"}đ</div>
                        </div>
                        <div className="flex flex-row items-center md:ml-32 ">
                          <div className="font-semibold md:w-20 md:h-8 w-10 h-6 rounded-md md:mb-0 flex justify-center items-center border border-colorBorder mr-3">
                            {/* {item1?.quantity} */}
                            <input
                              type="number"
                              value={item1.quantity}
                              className="w-full flex justify-center items-center text-center"
                            />
                          </div>
                          {/* <div
                    className="w-8 h-8 rounded-md flex justify-center items-center border border-colorBorder mr-3"
                    onClick={handleIncrease}
                  >
                    <PlusIconComponent style="stroke-colorPrimary w-6 h-6 cursor-pointer" />
                  </div>
                  <div
                    className="w-8 h-8 rounded-md flex justify-center items-center border border-colorBorder mr-3"
                    onClick={handleDecrease}
                  >
                    <MinusIcon style="stroke-colorPrimary w-6 h-6 cursor-pointer" />
                  </div> */}
                        </div>
                      </div>
                    </div>
                  )))
              }
              <div className="h-20 border-t border-t-colorBorder justify-end flex md:justify-start lg2:justify-end items-center">
                <div className="flex">
                  <div className="font-semibold text-sm flex items-center mr-2">
                    Tổng tiền :
                  </div>
                  <div className="text-colorThird font-semibold">
                    ₩{formatPrice}
                  </div>
                </div>
              </div>
            </div>
            <div onClick={handleConfirmOrder} className="flex justify-center">
              <Button className="h-10 w-28 bg bg-colorSecondary text-white">Xác nhận</Button>
            </div>
          </div>
        </div>

      )}
    </div>


  );
}

export default Order;
