/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react'
import ManageItems from '../manage-form';
import { Button, notification, Popconfirm, Select, Table } from 'antd';
import UserDetailIcon from '../../../../assets/icons/UserDetailIcon';
import DeleteIconComponent from '../../../../assets/icons/DeleteIcon';
import OrderService from '../../../../services/orderService';
import { useParams } from 'react-router-dom';
import BackIcon from '../../../../assets/icons/BackIcon';
const ManageOrderDetail: React.FC = () => {
    const { id } = useParams();
    const [data, setData] = useState<any>("");
    const [dataStatus, setDataStatus] = useState("");
    const [dataProduct, setDataProduct] = useState([]);

    const handleConfirm = async () => {

        const obj = {
            Id: id,
            Status: "Active"
        }
        const result = await OrderService.changeStatusOrder(obj);
        if (result.response?.data.message === "success") {
            setDataStatus('Active');
            notification.success({
                message: 'Xác nhận đơn hàng thành công!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
        AdminGetAll(id);
    };

    const handleCancel = async () => {

        const obj = {
            Id: id,
            Status: "Order"
        }
        const result = await OrderService.changeStatusOrder(obj);
        if (result.response?.data.message === "success") {
            setDataStatus('Order');
            notification.success({
                message: 'Đã hủy đơn hàng thành công!',
                showProgress: true,
                pauseOnHover: true,
            });
        }
        AdminGetAll(id);
    };

    const AdminGetAll = async (id: any) => {
        const result = await OrderService.AdminGetById(id);
        // console.log(result.data, 'result')
        if (result.data.message === 'success') {
            setData(result.data.data);
            setDataStatus(result.data.data.status);
            setDataProduct(result.data.data.orderItems);
        }
    };

    const columns = [
        {
            title: 'Hình ảnh',
            key: 'logo',
            render: (_: any, record: any) => {
                // console.log(record.product, 'record')
                const arrayImg = record.product.logo.split("|");
                return (
                    <img
                        alt={record.product.prodName}
                        src={arrayImg[0]}
                        className=" rounded-lg w-36 object-cover"
                    />
                )
            }
            ,
        },
        {
            title: 'Sản phẩm',
            key: 'productName',
            render: (_: any, record: any) => (
                <div>
                    <p>{record.product !== null && record.product.prodName}</p>
                </div>
            ),
        },
        {
            title: 'Thuộc tính sản phẩm',
            key: 'attributeItems',
            render: (_: any, record: any) => {
                // console.log(record.product, 'record')
                return (
                    <div>
                        {record.attributeItems.length > 0 ? (
                            record.attributeItems.map((item: any, index: any) => (
                                <p className='bg-gray rounded-md text-center py-1' key={index}>{item}</p>
                            ))
                        ) : (
                            <p className='bg-gray rounded-md text-center py-1'>Không có thuộc tính</p>
                        )}
                    </div>
                )
            }
            ,
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Số Tiền',
            dataIndex: 'price',
            key: 'price',
            render: (price: number) => `₩${price.toLocaleString('ko-KR')}`,
        },

    ];
    const onChange = (value: any) => {
        setDataStatus(value);
    }
    useEffect(() => {
        AdminGetAll(id);
    }, []);
    return (

        <div>
            <a href='/admin/don-hang' className='mb-5 flex items-center'>
                <BackIcon style='w-6 h-6 fill-colorSecondary mr-2' />
                <div className='text-colorSecondary'>Quay lại danh sách đơn hàng</div>
            </a>
            <Table columns={columns} dataSource={dataProduct} pagination={false} />
            <div style={{ marginBottom: 50 }}></div>
            <div className='flex items-center mb-5'>
                <h1 style={{ fontSize: 20, fontWeight: 'bold' }}>Chi tiết đơn hàng {data.id}</h1>
            </div>

            <div className='mb-2'>Họ tên: {data.fullName}</div>
            <div className='mb-2'>Số điện thoại: {data.phone}</div>
            <div className='mb-2'>Email: {data.email}</div>
            <div className='mb-2'>Địa chỉ: {data.address}</div>
            <div className='mb-2'>Trạng thái: <span className={`${dataStatus === 'Active' ? 'text-colorSecondary font-semibold' : 'text-colorThird font-semibold'}`}>{dataStatus === 'Active' ? 'Đã xác nhận' : 'Chờ xác nhận'}</span></div>
            <div className='mb-2'>Ghi chú: {data.note}</div>
            <div style={{ marginBottom: 10 }}></div>
            <div className='my-5'>
                <div className='flex justify-end'>
                    <button
                        onClick={handleConfirm}
                        className={`px-4 rounded-lg hover:bg-colorSecondary duration-300 font-semibold py-2 mr-2 bg-colorSecondary/60 text-white`}
                    >
                        Xác nhận
                    </button>
                    <button
                        onClick={handleCancel}
                        className={`px-4 rounded-lg hover:bg-colorThird duration-300 font-semibold py-2 bg-colorThird/60 text-white`}
                    >
                        Hủy xác nhận
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ManageOrderDetail
