import React from 'react'

interface IConProps {
	style?: string;
}

const LaptopAppleIconComponent: React.FC<IConProps> = ({ style }) => {
	return (
		<svg className={style} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 85.348 85.348" xmlSpace="preserve">
			<g>
				<g>
					<path className={style} d="M77.45,57.691v-3.16V35.563V16.595c0-1.736-1.424-3.16-3.164-3.16h-63.22
			c-1.739,0-3.167,1.417-3.167,3.16v18.968v18.968v3.16L0,67.175c0,2.613,2.122,4.738,4.738,4.738H80.61
			c2.616,0,4.738-2.126,4.738-4.738L77.45,57.691z M49.002,70.339H36.358c-0.44,0-0.791-0.351-0.791-0.791s0.351-0.791,0.791-0.791
			h12.644c0.433,0,0.791,0.351,0.791,0.791C49.782,69.988,49.435,70.339,49.002,70.339z M29.644,67.179l2.412-2.895h21.237
			l2.412,2.895H29.644z M72.998,52.101c0,1.525-1.242,2.759-2.756,2.759H15.106c-1.514,0-2.756-1.245-2.756-2.759V19.032
			c0-1.525,1.242-2.759,2.756-2.759h55.136c1.514,0,2.756,1.242,2.756,2.759C72.998,19.032,72.998,52.101,72.998,52.101z"/>
					<path className={style} d="M56.052,42.778c-0.762-0.329-1.442-0.777-2.058-1.335c-0.44-0.397-0.816-0.866-1.12-1.378
			c-0.222-0.369-0.426-0.748-0.58-1.138c-0.154-0.39-0.247-0.812-0.351-1.217c-0.075-0.301-0.125-0.601-0.143-0.909
			c-0.025-0.673-0.014-1.335,0.143-1.997c0.132-0.54,0.297-1.059,0.54-1.553c0.598-1.199,1.471-2.129,2.623-2.809
			c0.079-0.061,0.168-0.111,0.268-0.175c-0.075-0.075-0.122-0.136-0.175-0.2c-0.619-0.769-1.367-1.399-2.212-1.89
			c-0.462-0.268-0.948-0.494-1.432-0.698c-0.286-0.122-0.594-0.197-0.895-0.261c-0.279-0.064-0.58-0.089-0.862-0.125
			c-0.748-0.089-1.489,0.014-2.215,0.154c-0.354,0.064-0.687,0.183-1.031,0.293c-0.283,0.082-0.558,0.197-0.83,0.293
			c-0.24,0.082-0.483,0.197-0.727,0.261c-0.372,0.104-0.755,0.2-1.134,0.276c-0.68,0.147-1.353,0.025-2.011-0.136
			c-0.308-0.075-0.601-0.19-0.902-0.286c-0.354-0.115-0.698-0.24-1.056-0.358c-0.308-0.1-0.616-0.222-0.934-0.286
			c-0.372-0.079-0.759-0.132-1.142-0.2c-0.82-0.129-1.628,0-2.426,0.215c-0.412,0.107-0.812,0.29-1.202,0.462
			c-0.63,0.265-1.192,0.655-1.714,1.081c-0.705,0.555-1.299,1.224-1.811,1.958c-0.347,0.487-0.641,0.988-0.83,1.553
			c-0.15,0.419-0.286,0.845-0.404,1.278c-0.089,0.344-0.175,0.684-0.222,1.034c-0.057,0.387-0.082,0.784-0.1,1.174
			c-0.021,0.476-0.029,0.952-0.014,1.417c0.018,0.447,0.061,0.895,0.107,1.335c0.05,0.419,0.111,0.837,0.186,1.26
			c0.057,0.301,0.136,0.594,0.215,0.891c0.111,0.429,0.218,0.863,0.358,1.281c0.161,0.483,0.34,0.963,0.537,1.424
			c0.265,0.623,0.54,1.242,0.855,1.843c0.548,1.034,1.188,2.026,1.904,2.963c0.48,0.63,0.984,1.231,1.593,1.746
			c0.701,0.608,1.474,1.063,2.412,1.231c0.812,0.14,1.564-0.029,2.301-0.308c0.372-0.147,0.73-0.308,1.099-0.462
			c0.616-0.265,1.267-0.426,1.936-0.49c0.812-0.075,1.603-0.057,2.384,0.175c0.433,0.125,0.859,0.286,1.274,0.462
			c0.594,0.243,1.17,0.519,1.818,0.623c0.888,0.14,1.682-0.064,2.444-0.483c0.684-0.372,1.27-0.873,1.782-1.46
			c0.501-0.58,0.97-1.188,1.417-1.811c0.455-0.63,0.888-1.27,1.267-1.943c0.462-0.823,0.859-1.693,1.285-2.541
			c0.05-0.097,0.011-0.165-0.075-0.211C56.138,42.792,56.091,42.792,56.052,42.778z"/>
					<path className={style} d="M43.945,27.027c0.551-0.179,1.07-0.437,1.564-0.755c0.694-0.455,1.288-1.016,1.779-1.693
			c0.512-0.687,0.898-1.446,1.152-2.265c0.186-0.601,0.301-1.21,0.258-1.9c0-0.136,0.007-0.329,0-0.523
			c-0.007-0.222-0.047-0.261-0.258-0.222c-0.455,0.097-0.923,0.175-1.364,0.34c-0.512,0.2-1.002,0.44-1.467,0.719
			c-1.077,0.637-1.911,1.51-2.537,2.588c-0.293,0.508-0.523,1.027-0.691,1.578c-0.075,0.261-0.143,0.54-0.179,0.82
			c-0.061,0.455-0.068,0.923-0.007,1.385c0.014,0.132,0.054,0.193,0.19,0.19C42.918,27.282,43.437,27.189,43.945,27.027z"/>
				</g>
			</g>
		</svg>
	)
}

export default LaptopAppleIconComponent