const me = async () => {
  try {
    let userData = localStorage.getItem("user");
    let data = userData ? JSON.parse(userData) : {};
    return { data: data };
  } catch (error) {
    throw error;
  }
};

const UserService = {
  me,
};

export default UserService;
