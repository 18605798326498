import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import todoReducer from "../slices/todoSlice";
import rootSaga from "../saga";
import sagaSlice from "../slices/sagaSlice";
import clientSlice from "../slices/clientSlice";

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    todo: todoReducer,
    saga: sagaSlice,
    client: clientSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
