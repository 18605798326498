import React, { useState, useEffect, useRef } from "react";
import { Card, notification, Popover } from "antd";
import ProductCollectionService from "../../services/product-collection";
import CartIconComponent from "../../assets/icons/CartIcon";
import DatabaseIconComponent from "../../assets/icons/DatabaseIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import GiftIconComponent from "../../assets/icons/GiftIcon";

const ItemListCollectionComponent = ({ productCollection }: any) => {

    const getSlidesToShow = () => {
        const width = window.innerWidth;
        if (width >= 1600) return 6;
        if (width >= 1400) return 5;
        if (width >= 1200) return 4;
        // if (width >= 992) return 3;
        if (width >= 576) return 3;
        if (width >= 425) return 2;
        return 2;
    };
    const [startTouchX, setStartTouchX] = useState<number | null>(null);
    const [currentTouchX, setCurrentTouchX] = useState<number | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(getSlidesToShow());
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [api, contextHolder] = notification.useNotification();

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        setStartTouchX(event.touches[0].clientX);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        setCurrentTouchX(event.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (startTouchX !== null && currentTouchX !== null) {
            const diffX = startTouchX - currentTouchX;
            if (Math.abs(diffX) > 50) {
                if (diffX > 0) {
                    nextSlide();
                } else {
                    prevSlide();
                }
            }
        }
        setStartTouchX(null);
        setCurrentTouchX(null);
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            const nextIndex = prevIndex + 1;
            if (nextIndex >= productCollection.length) {
                // Khi đã đến cuối, quay về đầu.
                return 0;
            }
            return nextIndex;
        });
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            const prevIndexWithOffset = prevIndex - 1;
            if (prevIndexWithOffset < 0) {
                // Khi đang ở đầu, quay về cuối.
                return productCollection.length - 1;
            }
            return prevIndexWithOffset;
        });
    };



    useEffect(() => {
        const handleResize = () => {
            const slidesToShow = getSlidesToShow();
            setItemsPerPage(slidesToShow);
            setCurrentIndex((prevIndex) =>
                Math.min(prevIndex, Math.floor(productCollection.length / slidesToShow))
            );
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const renderedProducts = productCollection;


    return (
        <div className="flex items-center relative">
            {contextHolder}
            <button
                onClick={prevSlide}
                className={`bg-colorSecondary bg-opacity-80 md:block hidden hover:bg-opacity-100 rounded-full text-white border-none md:px-4 px-2 md:py-2.5 py-0.5 cursor-pointer absolute left-0 top-1/2 transform -translate-y-1/2 z-10 ${productCollection.length <= 5 ? "hidden" : ""
                    }`}
            >
                &#10094;
            </button>
            <div className="overflow-hidden w-full">
                <div
                    className="flex transition-transform duration-2000 ease-linear"
                    style={{
                        transform: `translateX(-${currentIndex * (100 / itemsPerPage)}%)`,
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {renderedProducts.map((product: any, index: number) => {
                        return (
                            <div
                                key={index}
                                className="p-2.5 rounded-lg md:bg-white text-sm flex-shrink-0"
                                style={{
                                    width: `${100 / itemsPerPage}%`,
                                }}
                            >
                                <Card
                                    size="small"
                                    hoverable
                                    cover={
                                        <a
                                            className="relative"
                                            href={`/bo-suu-tap/${product.nameSlug}`}
                                        >
                                            <img
                                                alt={product.name}
                                                src={`https://api.lehieucomputer.com${product.logo}`}
                                                className="w-full rounded-t-lg object-cover border-t border-l border-r border-gray"
                                            />
                                        </a>
                                    }
                                >
                                    <a
                                        href={`/bo-suu-tap/${product.nameSlug}`}
                                        className="hover:text-colorThird xs:text-base text-sm duration-400 overflow-hidden text-ellipsis line-clamp-2 h-11"
                                    >
                                        {product.name}
                                    </a>
                                </Card>
                            </div>
                        );
                    })}
                </div>
            </div>
            <button
                onClick={nextSlide}
                className={`bg-colorSecondary bg-opacity-80 md:block hidden hover:bg-opacity-100 rounded-full text-white border-none md:px-4 px-2 md:py-2.5 py-0.5 cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2 z-10 ${productCollection.length <= 5 ? "hidden" : ""
                    }`}
            >
                &#10095;
            </button>
        </div>
    );
};

export default ItemListCollectionComponent;
