/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import HomeService from '../../services/home';


const SliderTextComponent: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [textArray, setTextArray] = useState([]);

    const fetchImages = async () => {
        const result = await HomeService.getTextTopByHome();

        if (result.data.message === 'success') {

            //   const imageArray = data
            //     .filter((item: any) => item.homeType === "BANNER_FIX")
            //     .map((item: any) => item.image);

            setTextArray(result.data.data);

            // console.log(result.data.data, 'data');
        }
    };
    // console.log(imageFirst, 'imageFirst');
    useEffect(() => {
        fetchImages();
    }, []);

    const nextSlide = () => {
        if (textArray.length > 0) {
            const newIndex = (currentIndex + 1) % textArray.length;
            setCurrentIndex(newIndex);
        }
    };

    const prevSlide = () => {
        if (textArray.length > 0) {
            const newIndex = currentIndex === 0 ? textArray.length - 1 : currentIndex - 1;
            setCurrentIndex(newIndex);
        }
    };

    useEffect(() => {
        timeoutRef.current = setTimeout(nextSlide, 8000);
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [currentIndex]);

    return (
        <div className="relative mx-auto overflow-hidden rounded-lg w-full max-w-full">
            <button
                onClick={prevSlide}
                className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-opacity-50 text-white p-2 rounded-full"
            >
                &#10094;
            </button>
            <div
                className="flex transition-transform duration-5000"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {textArray.map((text: any, index) => (
                    <div
                        key={index}
                        className="w-full flex-shrink-0 text-center text-lg font-semibold"
                    >
                        <div className="px-4 py-2 text-white uppercase xs:text-sm text-2xs">{text.text}</div>
                    </div>
                ))}
            </div>

            <button
                onClick={nextSlide}
                className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-opacity-50 text-white p-2 rounded-full"
            >
                &#10095;
            </button>
            {/* <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {textArray.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-colorSecondary' : 'bg-gray'}`}
                    ></button>
                ))}
            </div> */}
        </div>
    );
};

export default SliderTextComponent;
